import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from "@angular/core";


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
    }
}

@Component({
    selector: 'wol-datepicker',
    templateUrl: 'wol-datepicker.component.html',
    styleUrls: ['./wol-datepicker.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})

export class WolDatepickerComponent implements OnInit {
    model: NgbDateStruct | undefined | any;
    placement = 'top';
    @Output() dateChange = new EventEmitter();
    @Input() disabled = false;
    @Input() name = 'default' + Date.now();
    placeholder = 'yyyy/mm/dd';
    message = '';
    focusing = false;
    typed = false;
    isHover = false;
    constructor(private eRef: ElementRef) {

    }

    ngOnInit(): void {
    }

    onChange(value: any) {
        if (this.model && this.model !== '') {
            this.typed = true;

        }

        if (typeof this.model !== 'string') {
        }
    }

    get PLACEHOLDER(): string {
        return this.message || this.placeholder;
    }

    setMessage() {
        this.message = `Format wrong`
        this.disabled = true;
        let i = 2;
        let t = setInterval(() => {
            this.message = `Try again after ... ${i}`
            i--
            if (i < 0) {
                this.message = '';
                this.disabled = false;
                clearInterval(t);
            }

        }, 1000)
    }

    handleExecute() {
        if (this.typed) {
            if (typeof this.model === 'string') {
                this.model = undefined;
                this.setMessage();
            }
            this.dateChange.emit({
                [this.name]: this.model
            });
            this.focusing = false;
        }

    }
    clearResult() {
        this.model = undefined;
        this.dateChange.emit({
            [this.name]: this.model
        });
    }


    @HostListener('document:click', ['$event'])
    clickout(event: any) {

        if (event?.target?.id?.includes(this.name)) {
            this.focusing = true;
        }
        if (!event?.target?.id?.includes(this.name) && this.focusing) {
            this.handleExecute();

            this.focusing = false;
            this.typed = false;

        }

    }
}