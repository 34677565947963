<form class="form-inline">
    <div class="form-group">
        <div class="input-group" (mouseenter)="isHover =true" (mouseleave)="isHover = false">
            <input class="form-control" [id]="'dateInput'+name" placeholder="{{PLACEHOLDER}}" #buttonEl name="dp"
                [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [placement]="placement" [positionTarget]="buttonEl"
                (ngModelChange)="onChange($event)" [disabled]="disabled"
                [class.message]="message" [autocomplete]="'off'">
            <div class="input-group-append" [id]="'dateBtnDiv'+name">
                <div class="clear_btn" [class.show]="isHover && model">
                    <span style="color: #fff;cursor: pointer;" (click)="clearResult()" ngbTooltip="Clear">✖</span>
                </div>
                <button class="btn btn-outline-secondary calendar" [id]="'dateBtn'+name" type="button"
                    [disabled]="disabled" (click)="d.toggle()">
                    <i class="far fa-calendar" style="color: #fff;" [id]="'dateicon'+name"></i>
                </button>
            </div>
        </div>
    </div>
</form>