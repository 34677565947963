import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardItem } from '../../components/wol-card-asset/wol-card-asset.component';
import { WolAuthService, BaseService, EnvironmentService, ReloadPageService, TranslateService } from '../../services';
import copy from 'copy-to-clipboard';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { AppState, getCurrentUser } from '../../store/reducers';
import { PAGE_LOADING, PAGE_UN_LOADING } from '../../store/actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WolConfirmComponent } from '../../components/wol-confirm/wol-confirm.component';
import { WolAssetDetailService } from '../../services/pages/wol-asset-detail.service';
import { Transaction } from '@wol/shared';
import { WolBaseComponent } from '../../components/wol-base.component';


@Component({
    selector: 'wol-asset-detail-page',
    templateUrl: 'wol-asset-detail.component.html',
    styleUrls: ['./wol-asset-detail.component.scss']
})

export class WolAssetDetailPageComponent extends WolBaseComponent implements OnInit {
    constructor(
        private assetDetailService: WolAssetDetailService,
        private route: ActivatedRoute,
        public authService: WolAuthService,
        _env: EnvironmentService,
        baseService: BaseService,
        private reloadPageService: ReloadPageService,
        private toastService: ToastrService,
        private translateService: TranslateService,
        private store: Store<AppState>,
        private modalService: NgbModal,
        private router: Router
    ) {
        super(baseService, _env, authService);
        this.environment = this.env?.env;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

        store.pipe(select(getCurrentUser)).subscribe((val: any) => {
            // @ts-ignore
            if (val?.balances) {
                if (this.game?.toUpperCase()?.includes('MU')) {
                    this.wol_balance = val?.balances?.battle_city_mu_wol_balance;
                    this.ken_balance = val?.balances?.battle_city_mu_ken_balance;

                }
                if (this.game?.toUpperCase()?.includes('TANK')) {
                    this.wol_balance = val?.balances?.battle_city_tank_wol_balance;
                    this.ken_balance = val?.balances?.battle_city_tank_ken_balance;

                }
            }
        });
    }
    wol_balance = "0.00";
    ken_balance = "0.00";
    base64: any = '';
    buttonName = '';
    colorBtn = '';
    environment: any = {};
    address = '0xc415cb9b7ebb950bb770b5d7b6b45f3538bf4edd';
    listAttrs: any[] = []
    asset: CardItem | any = {};
    keysTable = ['hash', 'type', 'status', 'from', '', 'to', 'value', 'date']
    listTransactions = [{
        hash: 'abc',
        type: 'buying',
        from: ' abc',
        to: 'abcx',
        '': '<i class="fas fa-arrow-right"></i>',
        value: '100 wol',
        date: 1642556713537
    }];
    round = 0;
    itemLoading: any[] = [];
    allowQuery = true;
    currentCond: any = {};
    isShowSocials = false;
    game = '';
    viewMoreAssets: CardItem[] = [];
    isBox = false;
    isId = false;
    getAddress() {
        return !this.isBox ? this.address : ''
    }
    attr: any = null;
    isTableLoading = false;

    handleSelectType(value: any) {
        this.isTableLoading = true;
        this.currentCond[`type`] = value;
        this.setUpTransaction(this.currentCond);
    }

    goBack() {
        history.back();
    }

    openSocials() {
        this.isShowSocials = true;
    }

    setUpTransaction(condition: any) {
        if (this.game) {
            condition[`game`] = this.game;
        }
        if (this.asset?.id) {
            condition[`asset_id`] = this.asset?.id;
        }
        else if (this.asset?.box_id) {
            condition[`asset_id`] = this.asset?.box_id

        }
        this.listTransactions = [];
        // retry call if address = null || undefined
        let time_check = setInterval(() => {
            if (this.address) {
                clearInterval(time_check);
                this.assetDetailService.getAssetActivitiesByOwner(this.address, condition).then(res => {
                    if (res?.status === 'OK') {
                        //sort by change_at reduce
                        const data = res?.result?.sort((a: any, b: any) => {
                            return b?.change_at - a?.change_at
                        })
                        this.listTransactions = data?.map((item: any) => {
                            const dto: Transaction = {};
                            dto.hash = item?.tx_hash;
                            dto.from = item?.from_address;
                            dto.to = item?.to_address;
                            dto.status = item?.status;
                            dto.type = item?.type;
                            dto[``] = `<i class="fas fa-arrow-right"></i>`;
                            dto.value = parseFloat(item?.amount).toFixed(2) + ' ' + item?.coin;
                            dto.date = item?.change_at;
                            dto.highlight = ['hash', 'from', 'to'];
                            dto.capitalize = ['type', 'status']
                            dto.links = [
                                {
                                    key: 'hash',
                                    link: this.environment?.BSCSCAN_URL + '/' + item?.tx_hash
                                },
                                {
                                    key: 'from',
                                    link: this.environment?.TOKEN_BSCSCAN_URL + '/' + item?.from_address
                                },
                                {
                                    key: 'to',
                                    link: this.environment?.TOKEN_BSCSCAN_URL + '/' + item?.to_address
                                }
                            ]
                            return dto;
                        })
                        this.isTableLoading = false;
                    }
                })
            }
        }, 300)
    }


    async getDetail(token: string) {
        if (token.startsWith('t')) {
            return await this.assetDetailService.getAssetDetailById({
                token_id: token?.slice(1)
            })
        }
        else if (token.startsWith('b')) {
            this.isBox = true;
            const data = await this.getStaterBoxDetail(token?.slice(1))
            return data[0];
        }
        return await this.assetDetailService.getAssetDetailById({
            _id: token?.slice(1)
        })
    }

    async getViewMoreAsset(round = 0) {
        const cond: any = {
            category: this.asset?.category?.key,
            type: this.asset?.type?.key,
        };
        if (this.asset?.class?.key) {
            cond.class = this.asset?.class?.key;
        }

        if (this.game) {
            cond.game = this.game;
        }
        return await this.assetDetailService.getAssetByCondition(cond, round);
    }

    buttonContent() {
        switch (this.asset?.status) {
            case 'nft':
                this.buttonName = 'View on Marketplace';
                this.colorBtn = '#97A3B4';
                return;
            case 'minting':
                this.buttonName = 'Minting...';
                this.colorBtn = '#97A3B4';
                return;
            case 'in_game':
                if (this.asset?.owner) {
                    this.buttonName = 'Open';
                    this.colorBtn = '#00E8B0';
                }
                else {
                    this.buttonName = 'Buy now';
                    this.colorBtn = '#00E8B0';
                }
                return;
            case 'listing':
                this.buttonName = 'Buy now';
                this.colorBtn = '#00E8B0';
                return;
            default:
                this.buttonName = 'Buy now';
                this.colorBtn = '#00E8B0';
                return;
        }
    }

    async getStaterBoxDetail(box_id: string) {
        return await this.assetDetailService.getStarterBox(this.game, {
            _id: box_id
        })
    }

    setupGame = () => {
        const GAME = sessionStorage?.TARGET_AREA?.toLowerCase() ? 'battle_city_' + sessionStorage?.TARGET_AREA?.toLowerCase() : null;
        if (GAME) {
            this.game = GAME;
        }
    }

    ngOnInit() {
        this.setupGame();
        this.route.params.subscribe(params => {
            if (params?.code) {
                const codes = params?.code?.split('?');
                const code = codes[0];
                this.getDetail(code).then((res: any) => {
                    this.asset = res;

                    this.attr = res?.attribute;
                    this.game = res?.game?.key;
                    const target_area: any = sessionStorage.getItem('TARGET_AREA');
                    if (this.game && !this.game?.includes(target_area?.toLowerCase())) {
                        sessionStorage.setItem('TARGET_AREA', this.game?.replace('battle_city_', '').toUpperCase());
                        this.reloadPageService.reloadPage();
                    }

                    this.setUpTransaction(this.currentCond);

                    this.handleAttrArea();
                    this.buttonContent();
                })
            }
        })

    }

    async buyItem(item: CardItem) {
        const res = await this.assetDetailService.buyItem(
            {
                address: this.address,
                wol: this.wol_balance,
                ken: this.ken_balance
            },
            [
                {
                    name: item?.name,
                    description: item?.description,
                    image_url: item?.image?.replace('assets/images/', ''),
                    category: item?.category?.key,
                    type: item?.type?.key,
                    class: item?.class?.key,
                    game: item?.game?.key,
                    attribute: {
                        name: item?.name,
                        description: item?.description,
                        image_url: item?.image?.replace('assets/images/', '')
                    }
                }
            ]
        );


        if (res?.status === 'OK') {
            this.toastService.info('You have already bought ' + item?.name, '', {
                progressBar: true,
            })
            this.authService.getCurrentAccount().then();
        }
        else {
            this.toastService.error(`
            ${(res?.errors?.length === 0 ? [] : res?.errors).join('<br/>  ')}
            `, '', {
                progressBar: true,
            })
        }
        return res;
    }

    async mintItem(base64?: string) {
        try {
            this.store.dispatch(new PAGE_LOADING)
            const data = {
                name: this.asset?.name,
                description: this.asset?.description,
                metadata: `https://api.battlecity.io/#/item/${this.asset?.token_id}`,
                base64,
                address: this.address
            }
            const res = await this.assetDetailService.mintItem(data);
            this.toastService.info('You have already minted ' + this.asset?.name, '', {
                progressBar: true,
            })
            if (res) {
                const t = await this.assetDetailService.updateStatusAsset({
                    _id: this.asset?.id,
                    status: `minting`
                })
                if (t?.status === 'OK') {
                    // this.cardAssets =[];
                    // this.getTotalNumAsset();

                    // this.requestMyAssetByRound(this.round_asset);
                    this.store.dispatch(new PAGE_UN_LOADING);

                    return t;

                }
            }
            this.store.dispatch(new PAGE_UN_LOADING);


        } catch (error) {
            this.store.dispatch(new PAGE_UN_LOADING);

            console.log(error);
        }
        finally {
            this.store.dispatch(new PAGE_UN_LOADING);
        }
    }

    button_mint_item = async (base64Img?: string): Promise<any> => {
        await this.mintItem(base64Img)
    }

    handleClickButton() {
        if (this.asset?.status !== 'minting' && this.asset?.status !== 'nft') {
            this.modalService.open(WolConfirmComponent).result.then((result) => {
            }, (reason) => {
                if (reason !== 'no') {
                    if (this.buttonName === 'Mint') {
                        // 
                        this.onTap(this.button_mint_item);
                    }
                    if (this.buttonName === 'Buy now') {
                        this.buyItem(this.asset);
                    }
                }
            });
        }
        else if (this.buttonName === 'View on Marketplace') {
            this.router.navigateByUrl('/item/' + 'i' + this.asset?.id);
        }
        else if (this.buttonName === 'Open') {
            this.toastService.info('🚀 Feature is comming soon!')
        }
    }

    async getStatusAfterAction() {
        const cond = this.asset?.token_id ? { token_id: this.asset?.token_id } : {};
        const cond1 = this.asset?.id ? { _id: this.asset?.id } : {}
        let mainCond = {};
        if (Object.keys(cond).length !== 0) {
            mainCond = cond;
        }
        else if (Object.keys(cond1).length !== 0) {
            mainCond = cond1;
        }
        const res = await this.assetDetailService.getAssetDetailById({ ...mainCond });
        this.asset.status = res?.status
        this.buttonContent();
    }

    onTap(cb: any): void {
        var self = this;
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                self.base64 = reader.result;
                cb(self.base64)?.then(() => {
                    setTimeout(() => {
                        self.getStatusAfterAction();
                    }, 500)
                });
            }
            reader.readAsDataURL(xhr.response);
        };
        var img: any = document.getElementById('imgElement');
        xhr.open('GET', img.src);
        xhr.responseType = 'blob';
        xhr.send();
    }

    getImageUrl(source: string) {
        return location.origin + '/' + source;
    }

    handleAttrArea() {
        const attr = this.attr ? JSON.parse(this.attr) : this.attr;
        Object.entries(attr || {}).forEach(([key, item]) => {
            this.listAttrs.push({
                key: key,
                value: item
            })
        })
    }

    copyLink() {
        copy(location.origin + location.pathname);
        this.toastService.success(this.translateService.translate('messages.copied'));
    }

    iconGame = () => {
        return this.asset?.game?.key?.includes('mu') ? 'assets/images/mu-icon.svg' : 'assets/images/tank-icon.svg'
    }

    checkCategory() {
        const { category, type } = this.asset;
        return category?.value || type?.value || this.asset?.class?.value;
    }

    onScroll() {
        if (this.itemLoading.length === 0 && this.allowQuery) {
            this.itemLoading = [1, 2, 3, 4, 5, 6];
            this.getViewMoreAsset(this.round).then(res => {
                if (res) {
                    setTimeout(() => {
                        this.viewMoreAssets = [...this.viewMoreAssets, ...res];
                        this.round++;
                    }, 500)
                }

                if (!res || res?.length === 0) {
                    this.allowQuery = false
                }

            }).finally(() => {
                setTimeout(() => {
                    this.itemLoading = [];
                }, 500)
            })
        }

    }
}