import { Action } from '@ngrx/store';

export enum SearchActionTypes {
  SEARCH_BAR = `[SEARCH BAR] SEARCH BAR`,
}


export class SEARCH_BAR implements Action {
  readonly type = SearchActionTypes.SEARCH_BAR;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}
