import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-creator-hub',
  templateUrl: './creator-hub.component.html',
  styleUrls: ['./creator-hub.component.scss']
})
export class CreatorHubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
