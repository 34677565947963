import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './wol-base.service';
import { EnvironmentService } from '../env/wol-enviroment.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService extends BaseService {
  constructor(
    http: HttpClient,
    env: EnvironmentService,
  ) {
    super(http, env);
  }

  async getMasterDataByListClass(classList: string[]) {
    const result = await this.post('/common/master/find', {
      class: classList || [],
      system: 'battle_city',
    });

    if (result?.status === 'OK') {
      if (result?.result?.length !== 0) {
      }
    }

    return result;
  }

  async getCategoriesByGame(game: string) {
    const result = await this.post('/common/master/find', {
      class: 'category',
      parent_code: game,
    });

    if (result?.status === 'OK') {
      if (result?.result?.length !== 0) {
      }
    }

    return result;
  }

  async getAllGames() {
    const result = await this.post('/common/master/find', {
      class: 'game',
      system: 'battle_city',
    });
    if (result?.status === 'OK') {
      if (result?.result?.length !== 0) {
      }
    }

    return result;
  }
}
