import { AppState } from '.';
import { CardActionTypes } from '../actions/wol-card.action';

export interface CardState {
  isLoading: boolean;
  isLoadMore: boolean;
  dataLoadMore: any[];
  round: number;
}

const initialState: CardState = {
  isLoading: false,
  isLoadMore: false,
  dataLoadMore: [],
  round: 0,
};

export const Cardreducer = (
  state = initialState,
  action: { type: string; payload?: any }
): CardState => {
  switch (action.type) {
    case CardActionTypes.CARD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CardActionTypes.CARD_UN_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CardActionTypes.CARD_LOAD_MORE:
      return {
        ...state,
        isLoadMore: true,
      };
    case CardActionTypes.CARD_LOAD_MORE_DATA:
      return {
        ...state,
        dataLoadMore: action.payload,
      };
    case CardActionTypes.CARD_UN_LOAD_MORE:
      const checkLength = state.dataLoadMore.length !== 0;
      return {
        ...state,
        round: checkLength ? state.round++ : state.round,
        isLoadMore: false,
        dataLoadMore: [],
      };
    default:
      return state;
  }
};

//selector

export const getLoadingState = (state: AppState) => state.cardReducer.isLoading;
export const getLoadMore = (state: AppState) => state.cardReducer.isLoadMore;
export const getDataLoadMore = (state: AppState) =>
  state.cardReducer.dataLoadMore;
