import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'wol-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  constructor(
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.$gaService.pageView('/', 'Home page');
  }

}
