import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { CreatorHubComponent } from './pages/creator-hub/creator-hub.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TokenomicComponent } from './pages/tokenomic/tokenomic.component';

export const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'tokenomic',
    component: TokenomicComponent,
  },
  {
    path: 'creator-hub',
    component: CreatorHubComponent,
  },
  {
    path: 'private-sale',
    component: PrivateSaleComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
