<div class="allocation">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-4 detail">
        <div class="row">
          <div class="col-xl-12 col-md-6">
            <div class="title">{{ 'tokenomic.allocation' | translate }}</div>
            <wol-line [center]="false"></wol-line>
            <div class="space"></div>
            <span class="info"
              >{{ 'tokenomic.info' | translate }}
            </span>
          </div>
          <div class="col-xl-12 col-md-6">
            <div class="chart">
              <img class="image" src="assets/images/chart.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 text-area">
        <div class="line"></div>
        <!-- 1 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #ff36d3"></span>
            <span class="text text-space">{{ 'tokenomic.item-1' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">5%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-1' | translate }}</span>
            >
          </div>
        </div>

        <!-- 2 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #2ad9ff"></span>
            <span class="text text-space">{{ 'tokenomic.item-2' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">5%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-2' | translate }}</span
            >
          </div>
        </div>
        <!-- 3 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #d8e2ea"></span>
            <span class="text text-space">{{ 'tokenomic.item-3' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">1.5%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-3' | translate }}</span
            >
          </div>
        </div>

        <!-- 4 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #ffa044"></span>
            <span class="text text-space">{{ 'tokenomic.item-4' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">3.5%</span>
          </div>
          <div class="col-sm-7">
            <span class="text">{{ 'tokenomic.item-info-4' | translate }}</span>
          </div>
        </div>

        <!-- 5 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #c700e8"></span>
            <span class="text text-space">{{ 'tokenomic.item-5' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">2%</span>
          </div>
          <div class="col-sm-7">
            <span class="text">{{ 'tokenomic.item-info-5' | translate }}</span>
          </div>
        </div>

        <!-- 6 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #e789ff"></span>
            <span class="text text-space">{{ 'tokenomic.item-6' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">10%</span>
          </div>
          <div class="col-sm-7">
            <span class="text">{{ 'tokenomic.item-info-6' | translate }}</span>
          </div>
        </div>
        <!-- 7 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #ff59bd"></span>
            <span class="text text-space"
              >{{ 'tokenomic.item-7' | translate }}</span
            >
          </div>
          <div class="col-1">
            <span class="text">41%</span>
          </div>
          <div class="col-sm-7">
            <span class="text">{{ 'tokenomic.item-info-7' | translate }}</span>
          </div>
        </div>
        <!-- 8 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #ffc5e0"></span>
            <span class="text text-space"
              >{{ 'tokenomic.item-8' | translate }}</span
            >
          </div>
          <div class="col-1">
            <span class="text">10%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-8' | translate }}</span
            >
          </div>
        </div>
        <!-- 9 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #da0071"></span>
            <span class="text text-space"
              >{{ 'tokenomic.item-9' | translate }}
            </span>
          </div>
          <div class="col-1">
            <span class="text">2%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-9' | translate }}
            </span>
          </div>
        </div>

        <!-- 10 -->
        <div class="row space-left">
          <div class="col-sm-4 col-11 d-flex space-bottom">
            <span class="dot" style="background-color: #00d4a1"></span>
            <span class="text text-space">{{ 'tokenomic.item-10' | translate }}</span>
          </div>
          <div class="col-1">
            <span class="text">20%</span>
          </div>
          <div class="col-sm-7">
            <span class="text"
              >{{ 'tokenomic.item-info-10' | translate }}
            </span>
          </div>
        </div>
        <div class="note">
          <span class="note-text"
            >{{ 'tokenomic.note-1' | translate }}</span>
          <span class="note-text"
            >{{ 'tokenomic.note-2' | translate }}</span
          >
          <span class="note-text"
            >{{ 'tokenomic.note-3' | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
