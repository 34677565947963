import { Action } from '@ngrx/store';

// [Filter Feature]
// - navbar-filter-items-game
export enum MasterDataActionTypes {
  COLLECT_MASTER_DATA = `[MASTER DATA] COLLECT MASTER DATA`,
}

export class COLLECT_MASTER_DATA implements Action {
  readonly type = MasterDataActionTypes.COLLECT_MASTER_DATA;
  constructor(public payload: object) {}
}

export type MasterDataActionsUnion = COLLECT_MASTER_DATA;
