import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FILTER_CATEGORY, FILTER_GAME, FILTER_PRICE, FILTER_STATUS } from '../../store/actions';
import { AppState, getFilterState } from '../../store/reducers';

@Component({
    selector: 'wol-chip',
    templateUrl: `./wol-chip.component.html`,
    styleUrls: [`./wol-chip.component.scss`]
})
export class WolChipComponent {
    @Input() text: string = '';
    @Input() items: any[] = [];
    @Input() item: any;

    constructor(private store: Store<AppState>) {
    }

    removeHandler(item: any) {

        if (item?.id === 'price') {
            this.store.dispatch(new FILTER_PRICE({}));
        }
        else if (item?.id === 'category') {
            const findCate = this.items.filter((f: any) => f.id === item?.id);
            const removeCate = findCate.filter((f: any) => f?.key !== item?.key);
            this.store.dispatch(new FILTER_CATEGORY(removeCate));
        }
        else if (item?.id === 'game') {
            const findGame = this.items.filter((f: any) => f.id === item?.id);
            const removeGame = findGame.filter((f: any) => f?.key !== item?.key);
            this.store.dispatch(new FILTER_GAME(removeGame));
        }
        else if (item?.id === 'status') {
            const findStatus = this.items.filter((f: any) => f.id === item?.id);
            const removeStatus = findStatus.filter((f: any) => f.key !== item?.key);
            const ids = removeStatus.map((m: any) => m?.key);
            this.store.dispatch(new FILTER_STATUS(ids));

        }
    }
}
