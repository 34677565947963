import { Component, Input } from '@angular/core';

@Component({
  selector: 'wol-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent {
  @Input() style = 1;
  @Input() rotate = false;
  @Input() center = true;
}
