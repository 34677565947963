<div class="contact">
  <div class="group">
    <div class="sub">
      <div class="icon">
        <img
          class="icon_image"
          src="assets/images/wol.png"
          style="width: 5.375rem; height: 5.375rem"
        />
      </div>
      <div class="container text">
        <div class="row">
          <span class="col-lg-4 text1">{{
            'homepage.join-our-community' | translate
          }}</span>
          <span class="col-lg-6 text2"
            >{{ NUM_OF_PLAYERS }}+
            {{ 'homepage.players-love-us' | translate }}</span
          >
        </div>
      </div>
      <div class="container content">
        <div class="row">
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element">
              <div class="image">
                <img
                  src="assets/images/telegram.png"
                  style="width: 3.75rem; height: 3.75rem"
                />
              </div>
              <span class="text4">{{ 'homepage.telegram' | translate }}</span>
              <div class="connect">
                <a class="text3" href="{{ TELEGRAM_LINK }}" target="_blank">{{
                  'homepage.lets-chat' | translate
                }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element">
              <div class="image">
                <img
                  src="assets/images/discord.png"
                  style="width: 4.15rem; height: 3.125rem"
                />
              </div>

              <span class="text4">{{ 'homepage.discord' | translate }}</span>
              <div class="connect">
                <a class="text3" href="{{ DISCORD_LINK }}" target="_blank">{{ 'homepage.join-now' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element">
              <div class="image">
                <img
                  src="assets/images/facebook.png"
                  style="width: 3.75rem; height: 3.75rem"
                />
              </div>

              <span class="text4">{{ 'homepage.facebook' | translate }}</span>
              <div class="connect">
                <a class="text3" href="{{ FACEBOOK_LINK }}" target="_blank">{{
                  'homepage.follow-us' | translate
                }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element">
              <div class="image">
                <img
                  src="assets/images/youtube.png"
                  style="width: 4.5rem; height: 3.125rem"
                />
              </div>

              <span class="text4">{{ 'homepage.youtube' | translate }}</span>
              <div class="connect" style="width: 7.375rem;">
                <a class="text3" href="{{ YOUTUBE_LINK }}" target="_blank">{{ 'homepage.watch-us' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element">
              <div class="image">
                <img
                  src="assets/images/twiter.png"
                  style="width: 3.25rem; height: 2.75rem"
                />
              </div>

              <span class="text4">{{ 'homepage.twitter' | translate }}</span>
              <div class="connect">
                <a class="text3" href="{{ TWITTER_LINK }}" target="_blank">{{ 'homepage.join-now' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="element" style="border: none">
              <div class="image">
                <img
                  src="assets/images/medium.png"
                  style="width: 4.375rem; height: 4.375rem"
                />
              </div>

              <span class="text4">{{ 'homepage.medium' | translate }}</span>
              <div class="connect">
                <a class="text3" href="{{ MEDIUM_LINK }}" target="_blank">{{ 'homepage.read-now' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
