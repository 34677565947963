import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wol-table',
    templateUrl: 'wol-table.component.html',
    styleUrls: ['./wol-table.component.scss']
})

export class WolTableComponent implements OnInit, OnChanges {
    @Input() keys: string[] = []
    @Input() dataSource: any[] = [
    ]
    @Input() hideFilter = false;
    @Input() stickyColumns?: string[] = ['hash']
    @Output() searchCondition = new EventEmitter();
    @Input() width: any;
    @Input() hideTitle = false;
    @Input() titleTable = 'Transactions';
    @Input() searchByField = 'hash';
    defaultData = [];
    collectionSize = 0;
    pageSizes = [6, 12, 18, 24, 30, 36];
    page = 1;
    pageSize = 6;
    conditions: any = {

    }
    isFocusSearch = false;

    model!: NgbDateStruct;
    date!: { year: number; month: number; };
    constructor(private datePipe: DatePipe) {

    }
    @Input()
    isLoading = true;

    ngOnInit() {
        setTimeout(() => {
            this.isLoading = false;
        }, 500)
    }

    setLoading() {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 400)
    }

    getMaxString(val: string) {
        if (val?.length <= 20) {
            return val;
        }
        return val?.slice(0, 20) + `...`;
    }


    handleNavigate(data: any, key: string) {
        if (data?.links && data?.links?.length !== 0) {
            const findLink = data?.links?.find((f: any) => f?.key === key);
            if (findLink && findLink?.link) {
                location.href = findLink?.link;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const key in changes) {
            if (Object.prototype.hasOwnProperty.call(changes, key)) {
                if (key === 'dataSource') {
                    this.dataSource = this.dataSource.map((item: any) => {
                        let res = item;
                        if (item?.hash) {
                            res.hashTooltip = res?.hash;
                            res.hash = this.getMaxString(res?.hash);
                        }
                        if (item?.from) {
                            res.fromTooltip = res?.from;
                            res.from = this.getMaxString(res?.from);
                        }
                        if (item?.to) {
                            res.toTooltip = res?.to;
                            res.to = this.getMaxString(res?.to);
                        }
                        return res;
                    })
                    this.collectionSize = this.dataSource.length;
                    this.defaultData = this.deepClone(this.dataSource);
                    this.refreshData();
                }

                if (key === 'isLoading') {
                    if (this.isLoading) {
                        this.setLoading();
                    }
                }

            }
        }
    }

    getFieldName = () => {
        const string = this.searchByField === 'tx_hash' ? 'hash' : this.searchByField;
        return string.replace('_', '');
    }

    getToolTip(data: any, key: string) {
        return data[`${key}Tooltip`];
    }

    handleSearchByField(val: any) {
        if (!JSON.stringify(this.conditions).includes(JSON.stringify(val))) {
            this.setLoading()
        }
        this.conditions[this.searchByField] = val;
        this.searchCondition.emit(this.conditions);
    }

    handleSelectType(val: any) {
        if (!JSON.stringify(this.conditions).includes(JSON.stringify(val))) {
            this.setLoading()
        }
        this.conditions.type = val;
        this.searchCondition.emit(this.conditions)
    }

    handleSelectCoin(val: any) {
        if (!JSON.stringify(this.conditions).includes(JSON.stringify(val))) {
            this.setLoading()
        }
        this.conditions.coin = val;
        this.searchCondition.emit(this.conditions)
    }

    handleSelectDate(val: any) {
        if (!JSON.stringify(this.conditions).includes(JSON.stringify(val))) {
            this.setLoading()
        }
        const keys = Object.keys(val);

        if (keys.includes('date_from')) {
            this.conditions.date_from = this.convertDateToUnixtime(val?.date_from);
        }
        else {
            this.conditions.date_to = this.convertDateToUnixtime(val?.date_to);
        }
        this.searchCondition.emit(this.conditions)
    }

    convertDateToUnixtime(date: NgbDate): number | null {
        if (date) {
            const d = new Date(date.year, date.month - 1, date.day);
            return d.getTime();
        }
        return null;
    }

    deepClone(data: any) {
        const d = JSON.stringify(data);
        return JSON.parse(d);
    }

    getData(key: string, val: any) {
        if (key === 'date') {
            return this.datePipe.transform(val, 'yyyy-MM-dd hh:mm:ss');
        }
        return val;
    }

    refreshData() {
        this.dataSource = this.defaultData
            .map((d: any, i) => ({ ...d, _id: i + 1 }))
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
}

