import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TokenomicComponent } from './pages/tokenomic/tokenomic.component';
import { CreatorHubComponent } from './pages/creator-hub/creator-hub.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { baseReducers, WolFooterModule, WolTopButtonModule, UiModule } from '@wol/ui';
import { ContactComponent } from './components/contact/contact.component';
import { PartnerComponent } from './components/partner/partner.component';
import { TeamComponent } from './components/team/team.component';
import { BackgroundComponent } from './components/background/background.component';
import { HeaderComponent } from './components/header/header.component';
import { IntroduceComponent } from './components/introduce/introduce.component';
import { EventComponent } from './components/event/event.component';
import { DetailComponent } from './components/detail/detail.component';
import {
  APP_BASE_HREF,
} from '@angular/common';
import { LineComponent } from './components/line/line.component';
import { ArrowComponent } from './components/arrow/arrow.component';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CoinComponent } from './components/coin/coin.component';
import { AllocationComponent } from './components/allocation/allocation.component';
import { ProfitComponent } from './components/profit/profit.component';
import { PrivateSalePopupComponent } from './components/private-sale-popup/private-sale-popup.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { DialogModule } from 'primeng/dialog';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { StoreModule } from '@ngrx/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AnnouncementPopupComponent } from './components/announcement-popup/announcement-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TokenomicComponent,
    CreatorHubComponent,
    ContactComponent,
    PartnerComponent,
    TeamComponent,
    BackgroundComponent,
    HeaderComponent,
    IntroduceComponent,
    EventComponent,
    DetailComponent,
    LineComponent,
    ArrowComponent,
    CoinComponent,
    AllocationComponent,
    ProfitComponent,
    PrivateSalePopupComponent,
    PrivateSaleComponent,
    AnnouncementPopupComponent
  ],
  imports: [
    AppRoutingModule,
    UiModule.forRoot(environment),
    ButtonModule,
    CarouselModule,
    WolTopButtonModule,
    WolFooterModule,
    NgxGoogleAnalyticsModule.forRoot(environment?.COMMON?.TRACKING_CODE),
    NgxGoogleAnalyticsRouterModule,
    DialogModule,
    StoreModule.forRoot({ ...baseReducers }, { initialState: {} }),
    FontAwesomeModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
