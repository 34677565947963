<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-6 col-sm-6 col-xl-3">
        <div class="grid-area grid-area-1">
          <div class="name">
            <img class="image" src="assets/images/mangox-light.svg" />
          </div>
          <div class="title">
            <a href="{{ HOME }}" class="text2 cap" target="_blank">{{
              getTitle('footer.bring-the-legends-back')
            }}</a>
            <a href="{{ HOME }}" class="text2 cap" target="_blank">{{
              getTitle('footer.build-the-legends-up')
            }}</a>
            <a href="{{ HOME }}" class="text2 cap" target="_blank">{{
              getTitle('footer.legends-never-die')
            }}</a>
            <a href="{{ WOL }}" class="text2 cap" target="_blank">{{
              getTitle('footer.wol-ken-battlecity')
            }}</a>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-xl-2">
        <div class="grid-area grid-area-2">
          <div class="sub_title">
            <span class="text3 cap">{{
              getTitle('footer.our-ecosystem')
            }}</span>
          </div>
          <div class="title">
            <a href="{{ LEGENDS_OF_TANK }}" class="text2 cap" target="_blank">{{
              getTitle('common.legends-of-tank')
            }}</a>
            <a href="{{ MU_OF_HEROES }}" class="text2 cap" target="_blank">{{
              getTitle('common.mu-of-heroes')
            }}</a>
            <a href="{{ HOME }}" class="text2 cap" target="_blank">{{
              getTitle('common.world-of-legends')
            }}</a>
            <a href="{{ WHITEPAPER }}" class="text2 cap" target="_blank">{{
              getTitle('footer.whitepaper')
            }}</a>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-xl-2">
        <div class="grid-area grid-area-3">
          <div class="sub_title">
            <span class="text3 cap">{{ getTitle('footer.explore') }}</span>
          </div>
          <div class="title">
            <a href="{{ GETTING_STARTED }}" class="text2 cap" target="_blank">{{
              getTitle('footer.getting-started')
            }}</a>
            <a href="{{ MARKETPLACE }}" class="text2 cap" target="_blank">{{
              getTitle('common.marketplace')
            }}</a>
            <!-- <a href="{{ AUCTION }}" class="text2 cap" target="_blank">{{ 'footer.auction') }}</a> -->
            <a class="text2 cap">{{ getTitle('footer.auction') }}</a>
            <a href="{{ NEWS }}" class="text2" target="_blank">{{
              getTitle('footer.news')
            }}</a>
            <a href="{{ ENCYCLOPEDIA }}" class="text2 cap" target="_blank">{{
              getTitle('footer.encyclopedia')
            }}</a>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-xl-2">
        <div class="grid-area grid-area-4">
          <div class="sub_title">
            <span class="text3 cap">{{ getTitle('footer.support') }}</span>
          </div>
          <div class="title">
            <a href="{{ FAQS }}" class="text2" target="_blank">{{
              getTitle('footer.faqs')
            }}</a>
            <a href="{{ TERMS_OF_USE }}" class="text2" target="_blank">{{
              getTitle('footer.terms-of-use')
            }}</a>
            <a href="{{ PRIVACY_POLICY }}" class="text2 cap" target="_blank">{{
              getTitle('footer.privacy-policy')
            }}</a>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-12 col-xl-3">
        <div class="grid-area grid-area-5">
          <div class="sub_title">
            <span class="text3 cap">{{ getTitle('footer.subscribe') }}</span>
          </div>
          <form class="subscribe" [formGroup]="mailInfo">
            <input
              type="text"
              id="mailInput"
              class="input"
              placeholder="{{ getTitle('footer.your-mail') }}"
              formControlName="mail"
              autocomplete="off"
            />
            <button type="button" class="submit cap" (click)="subscribe()">
              {{ getTitle('footer.submit') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="line_area">
    <div class="line"></div>
  </div>
  <div class="copyright">
    <span class="text3">{{ getTitle('footer.copyright') }}</span>
    >
  </div>
</div>
