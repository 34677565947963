import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '../../services';

@Component({
    selector: 'wol-confirm',
    templateUrl: 'wol-confirm.component.html',
    styleUrls: ['./wol-confirm.component.scss'],
    // add NgbModalConfig and NgbModal to the component providers
    providers: [NgbModalConfig, NgbModal]
})

export class WolConfirmComponent implements OnInit {
    isShow = false;
    constructor(config: NgbModalConfig, private modalService: NgbModal,private translateService : TranslateService) {
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
    }
    ngOnInit() {
        setTimeout(() => {
            this.isShow = true;
        }, 100)
    }

    getTranslate(code : string) {
        return this.translateService.translate(code);
    }
    open(content: any) {
        this.modalService.open(content);
    }

    close(content: any) {
        this.modalService.dismissAll(content)
    }
}