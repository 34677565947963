import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { KeyValueDto, STATUS } from '@wol/shared';
import { ToastrService } from 'ngx-toastr';
import { WolComponentService } from '../../services/components/wol-component.service';
import { AppState } from '../../store/reducers';
import { getCurrentUser } from '../../store/reducers/wol-auth.reducer';
import { WolConfirmComponent } from '../wol-confirm/wol-confirm.component';

@Component({
    selector: 'wol-card-asset',
    templateUrl: 'wol-card-asset.component.html',
    styleUrls: ['./wol-card-asset.component.scss'],

})

export class WolCardAssetComponent implements OnInit, AfterViewInit {
    @Input() item: CardItem | any;
    @Input() isLoading = false;
    @Input() hiddenAttributes: string[] = [];
    buttonName = 'Buy now';
    colorBtn = '#00E8B0';
    gameName = '';
    views = 0;
    address = '';
    base64: any = '';
    constructor(
        private route: ActivatedRoute,
        store: Store<AppState>,
        private modalService: NgbModal,
        private router: Router,
        private componentService: WolComponentService,
        private toastService: ToastrService,
    ) {
        this.route.params.subscribe(params => {
            if (params?.game) {
                this.gameName = params?.game;
            }
        })
        store.pipe(select(getCurrentUser)).subscribe((user: any) => {
            this.address = user?.address;
        })


    }

    ngAfterViewInit(): void {
        const img: any = document.getElementById('imgElement');
        // console.log(img)
    }

    ngOnInit() {
        if (this.item.status === 'listing') {
            this.item.status = STATUS.NFT;
        }

        this.buttonContent();
        if (this.item.category?.key === 'box') {
            if (this.item?.owner) {
                this.buttonName = 'Open';
            }
            else {
                this.buttonName = 'Buy now';
            }
        }
    }

    checkShowValue(value: any, field: string) {
        return !this.isLoading && (value || value === 0) && !this.hiddenAttributes.includes(field)
    }

    hanleClick() {
        if (this.item?.id || this.item?.inventory_id || this.item?.box_id) {
            this.componentService.createView(this.item?.game?.key, 'asset', this.address, this.item?.id || this.item?.inventory_id || this.item?.box_id).then()
        }

        if (this.item?.id) {

            this.router.navigateByUrl('/item/' + 'i' + this.item?.id);
        }
        else if (this.item?.token_id) {
            this.router.navigateByUrl('/item/' + 't' + this.item?.token_id);
        }
        else if (this.item?.box_id) {
            this.router.navigateByUrl('/item/' + 'b' + this.item?.box_id);

        }
    }

    async getStatusAfterAction() {
        const res = await this.componentService.getAssetDetailById({ _id: this.item?.id });
        this.item.status = this.getStatus(res?.status);
        this.buttonContent();
    }
    handleClickButtonCard() {
        const callback: any = this.item?.action;
        const names = ['Buy now', 'Mint'];
        if (names.includes(this.buttonName)) {
            this.modalService.open(WolConfirmComponent).result.then((result) => {
            }, (reason) => {
                if (reason !== 'no') {
                    if (this.buttonName === 'Buy now') {
                        callback?.button_buy_item().then()
                    }
                    else if (this.buttonName === 'Mint') {
                        // 
                        this.onTap(callback?.button_mint_item);
                    }
                }
            });
        }
        else if (this.buttonName === 'View on Marketplace') {
            this.router.navigateByUrl('/item/' + 'i' + this.item?.id);
        }
        else if (this.buttonName === 'Open') {
            this.toastService.info('🚀 Feature is comming soon!')
        }


    }

    onTap(cb: any): void {
        var self = this;
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                self.base64 = reader.result;
                cb(self.base64)?.then(() => {
                    setTimeout(() => {
                        self.getStatusAfterAction();
                    }, 500)
                });
            }
            reader.readAsDataURL(xhr.response);
        };
        var img: any = document.getElementById('imgElement');
        xhr.open('GET', img.src);
        xhr.responseType = 'blob';
        xhr.send();
    }


    buttonContent() {
        switch (this.item?.status) {
            case 'NFT':
                this.buttonName = 'View on Marketplace';
                this.colorBtn = '#97A3B4';
                return;
            case 'Minting':
                this.buttonName = 'Minting...';
                this.colorBtn = '#97A3B4';
                return;
            case 'In Game':
                this.buttonName = 'Mint';
                this.colorBtn = '#00E8B0';
                return;
            case 'listing':
                this.buttonName = 'View on Marketplace';
                return;
            default:
                return;
        }
    }

    getStatus(status: any) {
        switch (status) {
            case 'nft':
                return STATUS.NFT;
            case 'minting':
                return STATUS.MINTING;
            case 'in_game':
                return STATUS.IN_GAME;
            default:
                return;
        }
    }
}


export interface CardItem {
    id?: any;
    inventory_id?: string;
    box_id?: string;
    asset_id?: any;
    name?: string;
    price?: number;
    game?: KeyValueDto;
    token_id?: string;
    image?: string;
    medal?: string;
    attributes?: any;
    view?: number;
    status?: string;
    type?: KeyValueDto;
    create_at?: number;
    change_at?: number;
    category?: KeyValueDto;
    class?: KeyValueDto;
    description?: string;
    attribute?: any;
    countData?: number | any;
    owner?: string;
    action?: {
        button_buy_item?: () => void,
        button_mint_item?: (base64Img?: string) => void,
        button_view_marketplace?: () => void
    }
}