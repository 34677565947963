import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'wol-tokenomic',
  templateUrl: './tokenomic.component.html',
  styleUrls: ['./tokenomic.component.scss']
})
export class TokenomicComponent implements OnInit {
  constructor(
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.$gaService.pageView('/tokenomic', 'Tokenomic');
  }

}
