import { KeyValueDto } from '@wol/shared';
import { AppState } from '.';
import { MasterDataActionTypes } from '../actions/wol-master-data.action';

export interface MasterDataState {
  data?: KeyValueDto[] | any[];
}

 const initialState: MasterDataState = {
  data: [],
};

export const MasterDataReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case MasterDataActionTypes.COLLECT_MASTER_DATA:
      // filter
      const d = action?.payload || [];

      // data state
      const dataState: any = state.data;
      //merge data
      const mergeData: any[] = [...dataState, ...d];
      // distinct data
      const mapkey = mergeData?.map((a) => a?.key);

      const distinctData = mapkey?.map((f: any) => {
        return mergeData?.find((n) => n?.key === f);
      });

      return {
        ...state,
        data: distinctData || [],
      };
    default:
      return state;
  }
};


//selector

export const getMasterData = (state : AppState) => state.masterDataReducer.data;
