import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { WolConfirmComponent } from './wol-confirm.component';



@NgModule({
    declarations: [
        WolConfirmComponent
    ],
    imports: [
        FontAwesomeModule,
        BrowserModule,
        CommonModule,
    ],
    exports: [
        WolConfirmComponent
    ]
})
export class WolConfirmModule { }
