import { Component } from '@angular/core';
import { LINK } from '@wol/shared';

@Component({
  selector: 'wol-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent {
  AWS = LINK.AWS;
  BINANCE = LINK.BINANCE;
  ANTS = LINK.ANTS;
  BLG = LINK.BLG;
  HACKEN = LINK.HACKEN;
  UNICRYPT = LINK.UNICRYPT;
  BITKEEP = LINK.BITKEEP;
}
