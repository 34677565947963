import { Action } from '@ngrx/store';

// [Filter Feature]
// - navbar-filter-items-game
export enum PageActionTypes {
  ADD_ROUND_DATA = '[PAGE] ADD ROUND DATA',
  RESET_ROUND_DATA = '[PAGE] RESET ROUND DATA',
  PAGE_LOADING = '[PAGE] LOADING',
  PAGE_UN_LOADING = '[PAGE] PAGE_UN_LOADING'
}


export class ADD_ROUND_DATA implements Action {
    readonly type = PageActionTypes.ADD_ROUND_DATA;
    public payload: any;
    constructor(public _payload: object) {
      this.payload = _payload;
    }
  }

  export class RESET_ROUND_DATA implements Action {
    readonly type = PageActionTypes.RESET_ROUND_DATA;
  }

  export class PAGE_LOADING implements Action {
    readonly type = PageActionTypes.PAGE_LOADING;
  }

  export class PAGE_UN_LOADING implements Action {
    readonly type = PageActionTypes.PAGE_UN_LOADING;
  }