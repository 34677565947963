<div class="introduce">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 block">
        <div class="image">
          <div class="image_area image-1">
            <img
              src="assets/images/mu-of-heroes.png"
              style="width: 14rem; height: 14rem"
            />
          </div>
          <div class="image_area image-2">
            <img
            src="assets/images/wol.png"
            style="width: 5.6875rem; height: 5.6875rem"
          />
          </div>
          <div class="image_area image-3">
            <img
            src="assets/images/tank.png"
            style="width: 4.5rem; height: 5.875rem"
          />
          </div>
          <div class="image_area image-4">
            <img
            src="assets/images/heros.png"
            style="width: 15.25rem; height: 15.25rem"
          />
          </div>
        </div>
      </div>
      <div class="col-xl-7">
        <div class="content">
          <div class="text">{{'homepage.vision' | translate}}</div>
          <div class="group">
            <div class="item item-1">
              <span class="text2">{{ 'homepage.play-for-joy' | translate }}</span>
            </div>
            <div class="item item-2">
              <span class="text2">{{ 'homepage.earn-for-fun' | translate }}</span>
            </div>
            <div class="item item-3">
              <span class="text2">{{ 'homepage.own-for-real' | translate }}</span>
            </div>
          </div>
          <div class="detail">
            <span class="text3">{{'homepage.play-to-earn' | translate }}</span>
            <span class="text3">
              {{ 'homepage.text-detail' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
