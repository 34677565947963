import {Directive, ElementRef, Input} from '@angular/core';
import {WolAuthService} from '@wol/ui';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[validateEthAddress]',
    providers: [{provide: NG_VALIDATORS, useExisting: WolCheckEthAddressDirective, multi: true}]
})
export class WolCheckEthAddressDirective implements Validator {
    @Input('validateEthAddress') address = '';

    constructor(private el: ElementRef, private authService: WolAuthService) {
        if(!this.el.nativeElement.value) {
            this.el.nativeElement.innerText =this.address
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(this.el.nativeElement.value){
            const isValid = this.authService.isValidAddress(this.el.nativeElement.value);
            return !isValid ? {validateEthAddress: {value: control.value}} : null;
        }
        return null;
    }
}

