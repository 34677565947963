import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WolSharedModule } from '../../shared/wol-shared.module';
import { WolCardAssetComponent } from './wol-card-asset.component';


@NgModule({
    imports: [CommonModule,WolSharedModule],
    exports: [WolCardAssetComponent],
    declarations: [WolCardAssetComponent],
    providers: [],
})
export class WolCardAssetModule { }
