import { AppState } from ".";
import { EvnActionTypes } from "../actions/wol-env.action";

export interface EnvState {
    configs: any
}

export const initialState: EnvState = {
    configs: {}
};

export const EnvReducer = (state = initialState, action: { type: string; payload?: any }) => {
    switch (action.type) {
        case EvnActionTypes.GET_ENV:
            return {
                ...state,
            };
        case EvnActionTypes.ADD_ENV:
            return {
                ...state,
                configs: { ...state.configs, ...action.payload }
            };
        default:
            return state;
    }
}

export const getEnv = (state: AppState) => state.envReducer.configs;
