<div class="event">
  <div class="text">{{ 'common.legends-of-tank' | translate }}</div>
  <wol-line></wol-line>
  <div class="media_area">
    <div class="media">
      <div class="icon">
        <img src="assets/images/tank-icon.svg" style="width: 7.375rem; height: 9.375rem;" />
      </div>
      <!-- <div class="play" (click)="href()"> -->
        <div class="play">
        <!-- <a href="{{ LEGENDS_OF_TANK }}" class="text2" target="_blank">{{ 'common.play-now' | translate }}</a> -->
        <a class="text2">{{ 'common.coming-soon' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="container-fluid content">
    <div class="row">
      <div class="col-md-4 space">
        <div class="text3">{{ 'homepage.earning-arena' | translate }}</div>
        <div class="text4">
          {{ 'homepage.earning-arena-sub' | translate }}
        </div>
      </div>
      <div class="col-md-4 space">
        <div class="text3">{{ 'homepage.battle-arena' | translate }}</div>
        <div class="text4">
          {{ 'homepage.battle-arena-sub' | translate }}
        </div>
      </div>
      <div class="col-md-4 space">
        <div class="text3">{{ 'homepage.events' | translate }}</div>
        <div class="text4">
          {{ 'homepage.events-sub' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
