import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { FILTERS_FORM, isArrayFull, isNumber, isObjectFull, ItemStatus, NFTItemStatus, SortByItem, STATUS, VIEW_COUNT_HOT_TAG } from '@wol/shared';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { MasterService, TranslateService } from '../../services';
import { FILTER_CARD_CATEGORIES, FILTER_CARD_GAME, FILTER_CARD_STATUS, FILTER_CATEGORY, FILTER_RESET, FILTER_SORTBY } from '../../store/actions';
import { ADD_ROUND_DATA, RESET_ROUND_DATA } from '../../store/actions/wol-page.action';
import { AppState, FilterState, getFilterCardStatusState, getFilterSortBy, getFilterState } from '../../store/reducers';
import { CardItem } from '../wol-card-asset/wol-card-asset.component';

@Component({
    selector: 'wol-card-filter',
    templateUrl: 'wol-card-filter.component.html',
    styleUrls: ['./wol-card-filter.component.scss']
})

export class WolCardFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() items: CardItem[] = [];
    @Input() width = '60vw';
    @Input() itemLoading: any[] = [];
    @Input() totalNum = 0;
    @Input() isScroll = true;
    @Input() isEndData = false;
    @Input() filterForm: any[] = [FILTERS_FORM.STATUS, FILTERS_FORM.CATEGORIES, FILTERS_FORM.SORT_BY, FILTERS_FORM.ALL_GAMES];
    @Input() hiddenAttributes: string[] = [];
    @Input() actionCard: any = () => { };
    @Input() sortByItemsInput: string[] = [SortByItem.MOST_VIEWED, SortByItem.RECENTLY_CREATED, SortByItem.RECENTLY_UPDATED, SortByItem.LOW_PRICE_INCREASE, SortByItem.HIGH_PRICE_INCREASE]
    @Output() scrollChange = new EventEmitter();
    defaultItems: any[] = [];
    listItems: any[] = [];
    round = 0;
    isShowResultNum = false;
    sortByItems = [
        {
            code: SortByItem.MOST_VIEWED,
            value: 'common.sortby.view-des',
        },
        {
            code: SortByItem.RECENTLY_CREATED,
            value: 'common.sortby.created-des',
        },
        {
            code: SortByItem.RECENTLY_UPDATED,
            value: 'common.sortby.changed-des',
        },
        {
            code: SortByItem.LOW_PRICE_INCREASE,
            value: 'common.sortby.low-price-increas',
        },
        {
            code: SortByItem.HIGH_PRICE_INCREASE,
            value: 'common.sortby.high-price-increas',
        },
    ];
    sortbyCreated: any = [];
    all_categories: any[] = [];

    filterByStatuses = [
        {
            code: STATUS.IN_GAME,
            value: 'In Game'
        },
        {
            code: STATUS.NFT,
            value: 'NFT'
        },
        {
            code: STATUS.MINTING,
            value: 'Minting'
        }
    ]
    tabName: any = 'shop';
    currentCondition: any = {};
    all_games: any[] = [];
    sortby = new FormControl('');
    all_items = new FormControl('');
    select_categories = new FormControl('');
    select_games = new FormControl('')
    dataClone: any[] = [];
    filterState: FilterState = {}
    gameName = '';
    filterTags: any[] = []

    constructor(
        private translateService: TranslateService,
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private masterDataService: MasterService
    ) {



        this.route.params.subscribe(params => {
            if (params?.game) {
                this.gameName = params?.game;
            }
        })

        this.select_games.valueChanges.subscribe(val => {
            const dataParse = val ? JSON.parse(val) : {};
            const d = dataParse ? { ...(dataParse || {}), tab: this.tabName } : {};

            this.store.dispatch(new FILTER_CARD_GAME(d));
        })

        this.all_items.valueChanges.subscribe(val => {
            const dataParse = val ? JSON.parse(val) : {};
            const d = dataParse ? { ...(dataParse || {}), tab: this.tabName } : {};

            this.store.dispatch(new FILTER_CARD_STATUS(d));
        })

        // sort by
        this.sortby.valueChanges.subscribe((val) => {
            // parse data from string
            const dataParse = val ? JSON.parse(val) : {};
            const d = dataParse ? { ...(dataParse || {}), tab: this.tabName } : {};

            this.store.dispatch(new FILTER_SORTBY(d));
        });

        // category
        this.select_categories.valueChanges.subscribe((val) => {
            // parse data from string
            const dataParse = val ? JSON.parse(val) : {};
            const d = dataParse ? { ...(dataParse || {}), tab: this.tabName } : {};
            this.store.dispatch(new FILTER_CARD_CATEGORIES(d));
        });

        store
            .pipe(select(getFilterState))
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe((val) => {
                setTimeout(() => {
                    this.setFilterTag(val);
                    //dirty check
                    if (JSON.stringify(this.filterState) !== JSON.stringify(val)) {
                        this.filterState = val;
                        this.dataRendering();
                    }
                }, 200);
            });
        this.tabName = location.pathname + location.hash;
    }

    setFilterTag(val: any) {
        const { filterCategory, filterPrice, filterStatus, filterGameState, filterSortBy } = val;
        const statuses = filterStatus?.map((s: any) => {
            if (s === 'new') {
                return {
                    id: 'status',
                    value: NFTItemStatus.New
                };
            }
            return {
                id: 'status',
                value: NFTItemStatus['Hot Deals']
            };
        })
        const price: any = filterPrice?.value ? {
            ...filterPrice, id: 'price',
            value: `${filterPrice?.value}${filterPrice?.type} - ${filterPrice?.highValue}${filterPrice?.type}`
        } : null
        this.filterTags = [
            ...filterGameState?.map((g: any) => ({ ...g, id: 'game' })),
            ...filterCategory?.map((c: any) => ({ ...c, id: 'category' })),
            ...statuses?.map((s: any) => ({ ...s, id: 'status' })),
            price
        ];
        this.filterTags = this.filterTags.filter(f => f);
    }

    resetFilter() {
        this.store.dispatch(new FILTER_RESET());
    }

    setupDataMasterCombo() {
        this.masterDataService.getAllGames().then((games: any) => {
            if (games?.status === 'OK') {
                this.all_games = games.result || [];
            }
        });

        this.select_games.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), startWith(''))
            .subscribe((val) => {
                const dataParse = val ? JSON.parse(val) : {};
                this.masterDataService
                    .getCategoriesByGame(dataParse?.code)
                    .then((categories: any) => {
                        if (categories?.status === 'OK') {
                            this.all_categories = categories.result || [];
                        }
                    });
            });
    }

    handleClickCard(item: any) {
        if (item?.clickEvent) {
            // item?.clickEvent();
        }
    }

    showFilters(value: string) {
        return this.filterForm.includes(value);
    }

    get resultFilter(): boolean {
        return this.listItems.length === 0;
    }


    setupSortby() {
        if (this.sortByItemsInput.length !== 0) {
            this.sortbyCreated = this.sortByItems.filter(f => this.sortByItemsInput.includes(f?.code));
            // console.log(this.sortByItems,this.sortByItemsInput,this.sortbyCreated)
        }
        else {
            this.sortbyCreated = this.sortByItems;
        }
    }

    ngOnInit() {

        this.setupSortby();
        this.setupComboCategory();

        if (this.filterForm.length === 2 && (this.filterForm.includes(FILTERS_FORM.ALL_GAMES) && this.filterForm.includes(FILTERS_FORM.CATEGORIES))) {
            this.setupDataMasterCombo();
        }

        this.store.pipe(select(getFilterSortBy)).subscribe(val => {
            const value = this.sortByItems.find(f => f.code === val?.key);
            //sort by
            if (isObjectFull(value)) {

                if (val?.tabName === this.tabName) {
                    if (this.sortby.value !== JSON.stringify(value)) {
                        this.sortby.patchValue(JSON.stringify(value));
                    }
                }

            }
        })

        this.store.pipe(select(getFilterCardStatusState)).subscribe(val => {
            const data = val?.find((f: any) => f?.tab === this.tabName);
            if (data) {
                const { tab, ...rest } = data;
                const vl = JSON.stringify(rest);
                if (this.all_items.value !== vl) {
                    if (rest?.code) {
                        this.all_items.patchValue(vl);
                    }
                    else {

                        // this.all_items.setValue('')
                    }
                }
                this.currentCondition = data;
                this.handleFilterItems(data);
            }
            else {
                this.currentCondition = data;
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const key in changes) {
            if (Object.prototype.hasOwnProperty.call(changes, key)) {
                if (key === 'items') {
                    this.listItems = this.deepClone(this.items);
                    this.defaultItems = this.deepClone(this.items);

                    this.listItems = this.getListItemFromDataClone(this.listItems);
                    this.defaultItems = this.getListItemFromDataClone(this.defaultItems);

                    this.handleFilterItems(this.currentCondition);
                    setTimeout(() => {
                        this.dataRendering()

                    }, 200)
                }

                if (key === 'sortByItemsInput') {
                    this.setupSortby();
                }

            }
        }
    }

    dataRendering() {
        const {
            filterCategory,
            filterGameState,
            filterSortBy,
            filterPrice,
            filterStatus,
            filterCardStatus,
            filterCardGame,
            filterCardCategory
        } = this.filterState;
        this.dataClone = this.deepClone(this.defaultItems);
        // check status
        filterStatus?.forEach((item: any) => {
            this.handleFilterStatus(item);
        });

        // check price
        this.handleFilterPrice(filterPrice);

        // check game
        this.handleFilterGame(filterGameState);

        //check categories
        this.handleFilterCategories(filterCategory);

        //all games card
        this.handleFilterCardGame(filterCardGame)

        //all games category
        this.handleFilterCardCategory(filterCardCategory)

        //all items
        const data = filterCardStatus?.find((f: any) => f?.tab === this.tabName);
        if (data) {
            const { tab, ...rest } = data;
            const vl = JSON.stringify(rest);
            if (this.all_items.value !== vl) {
                if (rest?.code) {
                    this.all_items.patchValue(vl);
                }
                else {

                    // this.all_items.setValue('')
                }
            }
            this.currentCondition = data;
            this.handleFilterItems(data);
        }
        else {
            this.currentCondition = data;
        }

        this.handleSortBy(filterSortBy);
        this.listItems = this.getListItemFromDataClone(this.dataClone);
        this.isShowResultNum = true;

    }

    handleFilterStatusNew() {
        this.dataClone = this.dataClone.filter(
            (f: any) => f?.view > VIEW_COUNT_HOT_TAG
        );
    }

    handleFilterStatus(val: any) {
        switch (val) {
            case ItemStatus.HOT_DEAL:
                this.handleFilterStatusNew();
                return;
            case ItemStatus.NEW:
                this.handleSortList('change_at', 'des');
                return;
            default:
                return;
        }
    }

    handleFilterPrice(val: any) {
        const value = val?.value;
        const highValue = val?.highValue;
        if (isNumber(value) && isNumber(highValue)) {
            this.dataClone = this.dataClone.filter((f: any) => {
                return value <= f?.price && f?.price <= highValue;
            });
        }
    }

    handleFilterCardGame(val: any) {
        if (isObjectFull(val)) {

            this.dataClone = this.dataClone.filter((f: any) =>
                val?.code ? val?.code?.includes(f?.game?.key) : true
            );
        }
    }


    handleFilterCardCategory(val: any) {
        if (isObjectFull(val)) {

            this.dataClone = this.dataClone.filter((f: any) =>
                val?.code ? val?.code?.includes(f?.category?.key) : true
            );
        }
    }

    handleFilterGame(val: any) {
        if (val?.length !== 0 && isArrayFull(val)) {
            const keys = val.map((m: any) => m?.key);
            this.dataClone = this.dataClone.filter((f: any) => {
                return keys.includes(f?.game?.key)
            }
            );
        }
    }

    handleFilterCategories(val: any) {
        if (val?.length !== 0 && isArrayFull(val)) {
            const keys = val.map((m: any) => m?.key);
            this.dataClone = this.dataClone.filter((f: any) =>
                keys.includes(f?.category?.key)
            );
        }
    }

    handleFilterItems(val: any) {

        switch (val?.value) {
            case STATUS.NFT:
                this.dataClone = this.defaultItems.filter((f: any) => f.status === STATUS.NFT)
                break;
            case STATUS.IN_GAME:
                this.dataClone = this.defaultItems.filter((f: any) => f.status === STATUS.IN_GAME)
                break;

            case STATUS.MINTING:
                this.dataClone = this.defaultItems.filter((f: any) => f.status === STATUS.MINTING)
                break;

            default:
                this.dataClone = this.deepClone(this.defaultItems);
        }
    }

    handleSortList(field: string, type: string) {
        let dataClone = this.dataClone;
        if (type.includes('des')) {
            dataClone = dataClone.sort((a: any, b: any) => b[field] - a[field]);
        } else {
            dataClone = dataClone.sort((a: any, b: any) => a[field] - b[field]);
        }
    }
    handleSortBy(val: any) {
        // this.store.pipe(select(getFilterSortBy)).subscribe((val) => {

        // });

        if (val?.key && Object.keys(val).length !== 0) {
            switch (val?.key) {
                case SortByItem.RECENTLY_UPDATED:
                    this.handleSortList('change_at', 'des');
                    return;
                case SortByItem.RECENTLY_CREATED:
                    this.handleSortList('create_at', 'des');
                    return;
                case SortByItem.MOST_VIEWED:
                    this.handleSortList('view', 'des');
                    return;
                case SortByItem.HIGH_PRICE_INCREASE:
                    this.handleSortList('price', 'des');
                    return;
                case SortByItem.LOW_PRICE_INCREASE:
                    this.handleSortList('price', 'incres');
                    return;
                default:
                    this.listItems = this.defaultItems;
                    return;
            }
        }
    }

    translate(val: string) {
        return this.translateService.translate(val);
    }

    stringgifyData = (data: any) => JSON.stringify(data);

    deepClone(data: any) {
        const d = JSON.stringify(data || []);
        return JSON.parse(d);
    }

    getTotalItems() {
        return this.totalNum || this.defaultItems.length;
    }


    onScroll() {
        if (!this.isEndData && this.itemLoading.length === 0) {
            setTimeout(() => {
                this.round++;
                this.store.dispatch(new ADD_ROUND_DATA({
                    path: this.tabName || location.href,
                    round: this.round
                }))
            }, 200)
        }

    }

    getListItemFromDataClone(data: any[]) {
        const result = data.map(m => {
            const find = this.items.find(f => {
                let check = [];
                if (m?.category?.key) {
                    check.push(m?.category?.key === f?.category?.key);
                }

                if (m?.type?.key) {
                    check.push(m?.type?.key === f?.type?.key);
                }

                if (m?.class?.key) {
                    check.push(m?.class?.key === f?.class?.key)
                }
                check.push(m?.change_at === f?.change_at);
                check.push(m?.game?.key === f?.game?.key)
                return !check.includes(false);
            }
            );
            if (find) {
                return find;
            }
            return m;
        })
        return result;
    }

    setupComboCategory() {
        this.masterDataService.getCategoriesByGame(this.gameName ? 'battle_city_' + this.gameName : '').then(r => {
            if (r?.status === 'OK') {
                this.all_categories = r?.result || [];
            }
        })
    }

    checkActiveSelect(statement: string) {
        const tag = this.tabName.split('#')[1];
        return tag?.includes(statement);
    }

    ngOnDestroy(): void {
        this.store.dispatch(new RESET_ROUND_DATA())
    }
}

