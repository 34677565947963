import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseService } from './services/base/wol-base.service';
import { TranslateService } from './services/base/wol-translate.service';
import { HttpClientModule } from '@angular/common/http';
import { MoralisService } from './services/moralis/wol-moralis.service';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from 'ng-sidebar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WolTopButtonModule } from './components/wol-top-button/wol-top-button.module';
import { WolCommingSoonComponent } from './pages/wol-comming-soon/wol-comming-soon.component';
import { WolButtonModule } from './components/wol-button/wol-button.module';
import { WolCardAssetModule } from './components/wol-card-asset/wol-card-asset.module';
import { WolCardFilterModule } from './components/wol-card-filter/wol-card-filter.module';
import { WolSharedModule } from './shared/wol-shared.module';
import { WolAssetDetailPageComponent } from './pages/wol-asset-detail/wol-asset-detail.component';
import { WolTableModule } from './components/wol-table/wol-table.module';
import Moralis from 'moralis';
import { WolChipModule } from './components/wol-chip/wol-chip.module';

// Register token
export const ENVIRONMENT = new InjectionToken<string>('');

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    WolTopButtonModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      maxOpened: 2,
    }),
    WolCardAssetModule,
    WolCardFilterModule,
    WolSharedModule,
    WolTableModule,
    WolChipModule,
    WolButtonModule,
  ],
  declarations: [WolCommingSoonComponent,WolAssetDetailPageComponent],
  providers: [BaseService, TranslateService, MoralisService],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ToastrModule,
    SidebarModule,
    NgxSpinnerModule,
    WolTopButtonModule,
    WolButtonModule,
    WolCommingSoonComponent,
    WolCardAssetModule,
    WolCardFilterModule,
    WolSharedModule
  ],
})
export class UiModule {
  constructor(@Optional() @SkipSelf() parentModule?: UiModule) {
    if (parentModule) {
      throw new Error('UiModule is already loaded');
    }
  }
  static forRoot(environment: any): ModuleWithProviders<UiModule> {
    
    //setup lang default for application
    const langs = ['en_US', 'zh_CN', 'ja_JP', 'ko_KP', 'ru_RU', 'th_TH', 'tl_PH', 'vi_VN'];
    const LANG = localStorage.getItem('language');

    //check exist
    if (!LANG || !langs.includes(LANG)) {
      localStorage.setItem(
        'language',
        environment?.COMMON?.LANG_DEFAULT || 'en_US'
      );
    }
    if (environment[`MORALIS`]?.SERVER_URL && environment[`MORALIS`]?.APP_ID) {
      Moralis.start({
        appId: environment[`MORALIS`]?.APP_ID,
        serverUrl: environment[`MORALIS`]?.SERVER_URL
      }).then(r => {
        console.log("Moralis is initilized...")
      })
    }

    return {
      ngModule: UiModule,
      providers: [
        {
          provide: 'ENVIRONMENT',
          useValue: environment,
        },
      ],
    };
  }
}
