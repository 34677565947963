import { Directive, Input, HostBinding, ElementRef } from '@angular/core';
@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
    '[style]': 'style',
  },
})
export class WolImagePreloadDirective {
  @Input()
  src!: string;

  @Input()
  style!: string;
  @Input()
  default!: string;
  @HostBinding('class')
  className!: string;

  constructor(private el: ElementRef) {}

  updateUrl() {
    this.src = this.default  || 'assets/images/default-image.png';
  }
  load() {
    this.className = 'image-loaded';
  }
}
