import { NgModule } from '@angular/core';
import { WolSharedModule } from '../../shared/wol-shared.module';
import { WolTableComponent } from './wol-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WolDatepickerModule } from '../wol-datepicker/wol-datepicker.module';



@NgModule({
    imports: [WolSharedModule, NgbModule, FormsModule, ReactiveFormsModule, WolDatepickerModule],
    exports: [WolTableComponent],
    declarations: [WolTableComponent],
    providers: [],
})
export class WolTableModule { }

export interface TableExtension {
    highlight?: string[];
    capitalize?: string[];
    links?: { key: string; link: string }[]
}

export interface TransactionCondition {
    tx_hash?: string;
    type?: string;
    coin?: string;
    date_from?: number | null;
    date_to?: number | null;
}