import { Component } from '@angular/core';
import { LINK } from '@wol/shared';

@Component({
  selector: 'wol-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  NUM_OF_PLAYERS = 1500;
  TELEGRAM_LINK = LINK.TELEGRAM;
  DISCORD_LINK = LINK.DISCORD;
  FACEBOOK_LINK = LINK.FACEBOOK;
  YOUTUBE_LINK = LINK.YOUTUBE;
  TWITTER_LINK = LINK.TWITTER;
  MEDIUM_LINK = LINK.MEDIUM;
}
