<div class="area" [ngStyle]="{
  'justify-content': center ? 'center' : '',
          'transform' : rotate ? 'rotate(270deg)' : '',
          'margin-top': rotate ? '6rem' : '',
          'width': rotate ? '10px': ''
}">
  <div class="line_area">
    <div
      class="line"
      [ngStyle]="{
        'border-top':
          style === 1 ? '0.5px solid #00e8b0' : '0.5px solid #FF00C7'
      }"
    ></div>
  </div>
  <div class="line_block"
  [ngStyle]="{
    'justify-content': center ? 'center' : ''
  }">
    <div *ngIf="style === 1" class="block"></div>
    <div *ngIf="style !== 1" class="block2"></div>
  </div>
</div>
