<div class="table_wrapper" [style.width]="width">
    <div class="title_area" *ngIf="!hideTitle">
        <div class="line"></div>
        <div class="title">{{titleTable}}</div>
    </div>

    <div class="search_area" *ngIf="!hideFilter">
        <div class="search_filter">
            <div class="search_bar" [class.focus]="isFocusSearch">
                <input type="text" #searchField placeholder="{{'Search by ' + getFieldName()}}"
                    (keydown.enter)="handleSearchByField(searchField.value)" (focus)="isFocusSearch = true"
                    (focusout)="isFocusSearch = false">
                <div class="button_search" (click)="handleSearchByField(searchField.value)">
                    <img src="assets/images/search_btn.svg" alt="" width="13" height="13">
                </div>
            </div>
            <div class="type_select">
                <select class="form-select" aria-label="Type" #t (change)="handleSelectType(t.value)">
                    <option selected value="">{{ 'Type'}}</option>
                    <option value="deposit">{{ 'Deposit'}}</option>
                    <option value="withdraw">{{ 'Withdraw'}}</option>
                    <option value="rolling">{{ 'Rolling'}}</option>
                    <option value="mint">{{ 'Mint'}}</option>
                    <option value="list">{{ 'List'}}</option>
                    <option value="sell">{{ 'Sell'}}</option>
                    <option value="delist">{{ 'Delist'}}</option>
                </select>
            </div>
            <div class="wol_ken_select">
                <select class="form-select" aria-label="wol & ken" #c (change)="handleSelectCoin(c.value)">
                    <option selected value="">{{ 'WOL/KEN'}}</option>
                    <option value="wol">{{ 'Wol'}}</option>
                    <option value="ken">{{ 'Ken'}}</option>

                </select>
            </div>
        </div>
        <div class="titleDate">Date</div>
        <div class="date_filter">
            <div class="title">Date</div>
            <div class="from">
                <wol-datepicker [name]="'date_from'" (dateChange)="handleSelectDate($event)"></wol-datepicker>
            </div>
            <div class="from">
                <wol-datepicker [name]="'date_to'" (dateChange)="handleSelectDate($event)"></wol-datepicker>
            </div>
        </div>
    </div>

    <div style="display: flex; align-items: center;justify-content: center;height: 400px;" *ngIf="isLoading">
        <img src="assets/images/loading.gif" alt="">
    </div>

    <div style="display: flex; align-items: center;justify-content: center;height: 400px;"
        *ngIf="!isLoading && dataSource.length ===0">
        <span style="color: #fff;">There is no data</span>
    </div>

    <div class="table_area" *ngIf="!isLoading && dataSource.length !==0" style="overflow-x: auto;">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th [class.sticky]="stickyColumns?.includes(key)" *ngFor="let key of keys" scope="col">{{key}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of dataSource">
                    <ng-container *ngFor="let key of keys">
                        <td *ngIf="key" [class.spec]="data.highlight?.includes(key)"
                            [class.sticky]="stickyColumns?.includes(key)"
                            [class.capital]="data.capitalize?.includes(key)">
                            <span (click)="handleNavigate(data,key)"
                                ngbTooltip="{{getToolTip(data,key)}}">{{getData(key,data[key])}}</span>
                        </td>
                        <td *ngIf="!key" [innerHTML]="data[key]"></td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-between p-2 pagi" style="flex-wrap: wrap;" *ngIf="!isLoading && dataSource.length !==0">
        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
            (pageChange)="refreshData()">
        </ngb-pagination>

        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshData()">
            <option *ngFor="let p of pageSizes" [ngValue]="p">{{p}} items per page</option>
        </select>
    </div>
</div>