import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'wol-tabs',
    templateUrl: 'wol-tabs.component.html',
    styleUrls: ['./wol-tabs.component.scss']
})

export class WolTabsComponent implements OnInit, OnChanges {

    @Input() tabs: any[] = [];
    @Input() tabSelect = 'shop';
    @Output() tabListent = new EventEmitter();

    selectTab = 'shop';
    constructor(private router: Router) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const key in changes) {
            if (Object.prototype.hasOwnProperty.call(changes, key)) {
                if (key === 'tabSelect') {
                    this.selectTab = this.tabSelect;
                }
            }
        }
    }

    select(tab: any) {
        this.selectTab = tab?.id;
        this.tabListent.emit({
            value: tab
        });
        this.router.navigate([], {
            fragment: tab?.id,
        })
    }
}