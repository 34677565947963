<div class="area">
  <div class="text">{{ 'homepage.our-partners' | translate }}</div>
  <wol-line></wol-line>

  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col partner">
        <a href="{{ AWS }}" target="_blank">
          <img
          src="assets/images/aws.png"
          style="width: 7.375rem; height: 4.4375rem; margin-top: 5px"
        />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col partner">
        <a href="{{ BINANCE }}" target="_blank">
          <img
          src="assets/images/binance.png"
          style="width: 16.25rem; height: 3.3125rem; margin-top: 5px"
        />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col partner-n">
        <!-- <a href="{{ ANTS }}" target="_blank"> -->
          <a>
          <img
          src="assets/images/nts.png"
          style="width: 10.5625rem; height: 4rem; margin-top: 5px"
        />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col partner">
        <a href="{{ BLG }}" target="_blank">
          <img
          src="assets/images/blg.png"
          style="width: 5.375rem; height: 5.375rem; margin-top: 5px"
        />
        </a>
      </div>
    </div>
    <div class="row space">
      <div class="col-md-4 col partner">
        <a href="{{ HACKEN }}" target="_blank">
          <img
          src="assets/images/hacken.png"
          style="width: 16.4375rem; height: 6.8125rem; margin-top: 5px"
        />
        </a>
        
      </div>
      <div class="col-md-4 col partner">
        <a href="{{ BITKEEP }}" target="_blank">
          <img
          src="assets/images/bitkeep.png"
          style="width: 16.4375rem; height: 6.8125rem; margin-top: 5px"
        />
        </a>
        
      </div>
      <div class="col-md-4 col partner">
        <a href="{{ UNICRYPT }}" target="_blank">
          <img
            src="assets/images/unicrypt.png"
            style="width: 15rem; height: 4.25rem; margin-top: 5px"
          />
        </a>
      </div>
    </div>
  </div>
</div>
