import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Cookie } from "ng2-cookies/ng2-cookies";
import Moralis from 'moralis';
import { EnvironmentService } from '../env/wol-enviroment.service';

@Injectable()
export class BaseService {
  public enviroment: any;
  constructor(private http: HttpClient, public env?: EnvironmentService) {
    this.enviroment = env?._env || {};
  }



  async post(apiURL: string, request: any, options?: {}): Promise<any> {
    const headersRequest = {
      'Content-Type': 'application/json', // afaik this one is not needed
      Authorization: `Bearer ${Cookie.get('accessToken')}`, // token add if you have,
      ...options
    };
    const code: any = sessionStorage.getItem('TARGET_AREA');
    const source: any = sessionStorage.getItem('SOURCE');

    request['system'] = request?.system ?? this.enviroment?.SYSTEM_DEFAULT;

    request[`game`] = request?.game ?? this.enviroment[code]?.GAME ?? this.enviroment[source]?.GAME ?? "NO_CODE";

    request['api_key'] =request?.api_key ??  this.enviroment[code]?.API_KEY ?? this.enviroment[source]?.API_KEY ?? "NO_API_KEY";
    return this.http
      .post(
        this.enviroment?.BASE_URL + apiURL,
        request,
        { headers: headersRequest }
      )
      .pipe(
        tap((data: any) => {
          return data;
        }),
        catchError(
          this.handleError<any>({
            Method: 'Post',
            url: `${apiURL}`,
            request,
            response: null,
          })
        )
      ).toPromise();
  }

  /**
   *
   *
   * @private
   * @template T
   * @param {string} [operation='operation']
   * @param {T} [result]
   * @returns
   * @memberof RestApiService
   */
  private handleError<T>(operation: any, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      const errorMessage = {
        operation,
        error: error.message,
        errorDetail: error,
      };
      if (errorMessage?.errorDetail?.status === 401) {
        Cookie.delete('accessToken');
        if (location.pathname !== '/') {
          location.href = '';
        }
      }
      console.log(errorMessage);
      // TODO: better job of transforming error for user consumption
      // console.log(errorMessage);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
