import { Directive,ElementRef, HostBinding, Input } from '@angular/core';
@Directive({
  selector: '[status]',
  host: {
    // '(error)': 'updateUrl()',
    // '[src]': 'src',
    // '[style]': 'style',
  },
})
export class WolStatusDirective {
  @Input()
  status!: string;

  @HostBinding('status')
  statusAtt!: string;

  constructor(private el: ElementRef) {
    this.statusAtt = this.status;
  }

  load(v : any) {
      console.log(this.status,v)
  }

}
