<div class="address" *ngIf="isShow">
  <div class="info">
    <span class="contract">{{ 'common.our-contract' | translate }}</span>
    <img class="verified" src="assets/images/verified.png" alt="">
    <span class="wol_address pc">
      {{ MAIN_ADDRESS }}
    </span>
    <span class="wol_address mobile">
      {{ SHORT_ADDRESS }}
    </span>
    <button class="copy" (click)="copyAddress()">{{
      'common.copy' | translate
    }}</button>
  </div>
  <div class="close" (click)="close()">
    <!-- <fa-icon [icon]="faTimes"></fa-icon> -->
    <i class="fa-solid fa-xmark"></i>
  </div>
</div>
<div class="header" [ngStyle]="{ top: isShow ? '3.125rem' : '0' }">
  <div class="container-fluid" style="padding-left: 0">
    <div class="d-flex">
      <div class="d-flex" style="padding: 0">
        <div class="fa" (click)="toggle()">
          <i class="fa-solid fa-bars" style="font-size: 28px;"></i>
          <!-- <fa-icon [icon]="faBars" size="2x"></fa-icon> -->
        </div>
        <div class="logo" (click)="navigate(0)">
          <div class="name">
            <img
              src="assets/images/WOL-icon.svg"
              style="width: 1.375rem; margin-top: 5px"
            />
            <span class="text" style="text-transform: none">{{
              'common.home' | translate
            }}</span>
          </div>
        </div>
        <div class="left_menu">
          <a
            [href]="WHITEPAPER | safeUrl"
            class="text"
            target="_blank"
            [ngStyle]="{ color: pageKey === 1 ? '#FF36D3' : 'white' }"
            >{{ 'common.whitepaper' | translate }}</a
          >
          <span class="space">//</span>
          <a
            (click)="navigate(2)"
            class="text"
            [ngStyle]="{ color: pageKey === 2 ? '#FF36D3' : 'white' }"
            >{{ 'common.tokenomic' | translate }}</a
          >
          <span class="space">//</span>
          <a
            [href]="MARKETPLACE | safeUrl"
            class="text"
            [ngStyle]="{ color: pageKey === 3 ? '#FF36D3' : 'white' }"
            >{{ 'common.marketplace' | translate }}</a
          >
          <span class="space">//</span>
          <a
            [href]="WIKI | safeUrl"
            class="text"
            target="_blank"
            [ngStyle]="{ color: pageKey === 4 ? '#FF36D3' : 'white' }"
            >{{ 'common.wiki' | translate }}</a
          >
          <span class="space">//</span>
          <a
            [href]="NEWS | safeUrl"
            class="text"
            target="_blank"
            [ngStyle]="{ color: pageKey === 4 ? '#FF36D3' : 'white' }"
            >{{ 'common.news' | translate }}</a
          >
          <!-- <form class="language" [formGroup]="pageForm">
            <select
              class="form-select custom-select"
              formControlName="page"
            >
              <option *ngFor="let page of pages" [ngValue]="page">
                {{ page }}
              </option>
            </select>
          </form> -->
        </div>
      </div>
      <span style="flex: 1 1 auto"></span>
      <div class="d-flex justify-content-end">
        <div class="right_menu">
          <!-- <a href="#" class="text login">{{ 'common.login' | translate }}</a> -->
          <!-- <button class="button"><a href="#" class="btn_text">{{ 'common.play-now' | translate }}</a></button> -->
          <ul class="nav">
            <li class="dropdown">
              <button class="button">
                <a href="#" class="btn_text" (click)="joinTestnet()">JOIN TESTNET</a>
              </button>
              <!-- <ul class="dropdown-menu dropdown-area">
                    <li style="margin-bottom: 5px;"><a class="text link" href="{{ LEGENDS_OF_TANK }}" target="_blank">{{ 'common.legends-of-tank' | translate }}</a></li>
                    <li><a class="text link" href="{{ MU_OF_HEROES }}" target="_blank">{{ 'common.mu-of-heroes' | translate }}</a></li>
                </ul> -->
            </li>
          </ul>
          <form class="language" [formGroup]="languageForm">
            <select
              class="form-select custom-select"
              formControlName="language"
            >
              <option *ngFor="let lang of languages" [ngValue]="lang">
                {{ lang.name }}
              </option>
            </select>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="line-bot">
  <div class="line-gra"></div>
</div>
