<div class="profit">
  <div class="info">
    <div class="group">
      <div class="sub">
        <div class="icon">
          <img class="icon_image" src="assets/images/wol.png" />
        </div>
        <div class="title">{{ 'tokenomic.profit-share' | translate }}</div>
        <div class="text-area">
          <div class="text">
            {{ 'tokenomic.message-1' | translate }}
            <span class="special">{{ 'tokenomic.message-2' | translate }}</span>{{ 'tokenomic.message-3' | translate }}
            <span class="special">{{ 'tokenomic.message-4' | translate }}</span>{{ 'tokenomic.message-5' | translate }}
          </div>
        </div>
      </div>
      <div class="container partner">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="area">
              <div class="detail space">
                <div class="name blue">
                  {{ 'tokenomic.audit-by' | translate }}
                </div>
                <div class="logo">
                  <img src="assets/images/hacken.png" style="
                      width: 16.4375rem;
                      height: 6rem;
                      margin-top: -0.75rem;
                    " />
                </div>
                <div class="link">
                  <div class="text blue">
                    <a class="for-link blue" href="#" target="_blank">{{
                      'tokenomic.view-audit-pdf' | translate
                      }}</a>
                    <!-- <fa-icon [icon]="faAngleRight" size="1x"></fa-icon> -->
                    <i class="fa-solid fa-angle-right"></i>
                  </div>
                </div>
              </div>
              <div class="line">
                <wol-line [rotate]="true"></wol-line>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 group-2">
            <div class="area">
              <div class="detail space">
                <div class="name green">
                  {{ 'tokenomic.block-coin' | translate }}
                </div>
                <div class="logo">
                  <img src="assets/images/unicrypt.png" style="width: 15rem; height: 4.25rem; margin-top: 5px" />
                </div>
                <div class="link">
                  <div class="text green">
                    <a class="for-link green" href="#" target="_blank">{{
                      'tokenomic.view-unicrypt' | translate
                      }}</a>
                    <!-- <fa-icon [icon]="faAngleRight" size="1x"></fa-icon> -->
                    <i class="fa-solid fa-angle-right"></i>
                  </div>
                </div>
              </div>
              <div class="line">
                <wol-line [rotate]="true"></wol-line>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md detail group-3">
            <div class="name white">
              {{ 'tokenomic.available-on' | translate }}
            </div>
            <div class="logo">
              <a href="#" target="_blank">
                <img src="assets/images/pancakeswap.png" style="width: 15rem; height: 3.125rem; margin-top: 5px" />
              </a>
            </div>
            <div class="logo">
              <a href="#" target="_blank">
                <img src="assets/images/apeswap.png" style="width: 15rem; height: 3.125rem; margin-top: 5px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>