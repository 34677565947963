import * as authReducer from './wol-auth.reducer';
import * as cReducer from './wol-card.reducer';
import * as masterReducer from './wol-master-data.reducer';
import * as searchReducer from './wol-search.reducer';
import * as pageRedcucer from './wol-page.reducer';
import * as envReducer from './wol-env.reducer';
import { filterReducer, FilterState } from './wol-filter.reducer';

export interface AppState {
  authReducer: authReducer.AuthState;
  filterConditions: FilterState;
  cardReducer: cReducer.CardState,
  masterDataReducer: masterReducer.MasterDataState,
  searchReducer: searchReducer.SearchState,
  pageRedcucer: pageRedcucer.PageState,
  envReducer: envReducer.EnvState
}

export const baseReducers = {
  authReducer: authReducer.AuthReducer,
  filterConditions: filterReducer,
  cardReducer: cReducer.Cardreducer,
  masterDataReducer: masterReducer.MasterDataReducer,
  searchReducer: searchReducer.SearchReducer,
  pageRedcucer: pageRedcucer.PageReducer,
  envReducer: envReducer.EnvReducer
};

export * from './wol-card.reducer';
export * from './wol-filter.reducer';
export * from './wol-page.reducer';
export * from './wol-auth.reducer';
export * from './wol-env.reducer';
export * from './wol-master-data.reducer';
export * from './wol-search.reducer';