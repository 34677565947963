import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {WolAuthService} from '../services';
import {BaseService} from '../services/base/wol-base.service';
import {EnvironmentService} from '../services/env/wol-enviroment.service';


@Component({
    selector: 'wol-base-component',
    template: ``,
})
export class WolBaseComponent implements OnInit, OnDestroy {
    isLoading = new BehaviorSubject(false);
    env: any;
    address = '';

    constructor(private baseService?: BaseService, private _env?: EnvironmentService, public authService?: WolAuthService) {
        this.env = _env;
    }

    initialzieAuth() {
        this.authService?.getCurrentAccount().then(res => {
            this.address = res?.address;
        })
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    loading() {
        this.isLoading.next(true);
    }

    cancelLoading() {
        this.isLoading.next(false);
    }
}
