import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WolSharedModule } from '../../shared/wol-shared.module';
import { WolDatepickerComponent } from './wol-datepicker.component';


@NgModule({
    imports: [WolSharedModule, NgbModule, FormsModule, ReactiveFormsModule],
    exports: [WolDatepickerComponent],
    declarations: [WolDatepickerComponent],
    providers: [],
})
export class WolDatepickerModule { }
