<div class="detail_wrapper">
    <div class="content">
        <div class="header">
            <div class="text_back" (click)="goBack()">
                &#60; {{'detailpage.back' | translate}}
            </div>

            <div class="shared_copy">
                <div class="social_share" *ngIf="isShowSocials">
                    <share-button [image]="getImageUrl(asset.image)" [description]="asset.description" [theme]="'circles-dark'"
                        [button]="'facebook'"></share-button>
                    <share-button [image]="getImageUrl(asset.image)" [description]="asset.description" [theme]="'circles-dark'"
                        [button]="'twitter'"></share-button>
                    <share-button [image]="getImageUrl(asset.image)" [description]="asset.description" [theme]="'circles-dark'"
                        [button]="'telegram'"></share-button>

                </div>
                <span (click)="openSocials()" (clickOutside)="isShowSocials = false">{{'detailpage.share' |
                    translate}}</span>
                <span style="
                    margin-left: 18px;
                " (click)="copyLink()">{{'detailpage.copy-link' | translate}}</span>
            </div>
        </div>
        <div class="main">
            <div class="picture">
                <div class="header">
                    <img src="{{iconGame()}}" class="logo" alt="">
                    <div class="name_code">
                        <div class="name">{{asset?.name}}</div>
                        <div class="code" *ngIf="asset?.token_id">#{{asset?.token_id}}</div>
                    </div>
                    <div class="chip" *ngIf="asset?.type?.value">
                        <div class="text">{{asset?.type?.value}}</div>
                    </div>
                </div>
                <div class="content">
                    <img src="{{asset?.image}}" default="assets/images/noimg.png" alt="" id="imgElement">
                </div>
                <div class="owner_chip" ngbTooltip="{{getAddress()}}">
                    <div class="owner_text">
                        OWNER:
                    </div>
                    <div class="address">{{getAddress()}}</div>
                </div>
            </div>
            <div class="information">
                <div class="box">
                    <div class="header">
                        <div class="currency">
                            <div class="currency_text">
                                Curren Price
                            </div>
                            <div class="price">
                                <div class="wol_price">{{asset?.price}} WOL</div>
                                <span style="margin: 10px;color: #57586c;">~</span>
                                <div class="bnb_price">{{asset?.price | wol_currency}}</div>
                            </div>
                        </div>
                        <wol-button [background]="'#FF36D3'" [color]="'#FFFFFF'" [text]="buttonName" (click)="handleClickButton()" *ngIf="buttonName"></wol-button>
                    </div>
                    <div style="width: 100%;height: 0px;border: 1px solid #353645;"></div>
                    <div class="info">
                        <div class="about_text">
                            ABOUT
                        </div>
                        <div *ngIf="!asset?.description" no_information> {{'common.data.no-information' | translate}}
                        </div>

                        <div class="about">
                            {{asset?.description}}
                        </div>
                        <div class="property_text">PROPERTIES</div>
                        <div *ngIf="!checkCategory()" no_information> {{'common.data.no-information' | translate}}</div>

                        <div class="property_thumbnail">
                            <div class="item" *ngIf="asset?.category?.value">
                                <div class="img">
                                    <img src="" default="" alt="">
                                </div>
                                <div class="des">
                                    <div class="type_text">CATEGORY</div>
                                    <div class="value">{{asset?.category?.value}}</div>
                                </div>
                            </div>
                            <div class="item" *ngIf="asset?.type?.value">
                                <div class="img">
                                    <img src="" default="" alt="">
                                </div>
                                <div class="des">
                                    <div class="type_text">TYPE</div>
                                    <div class="value">{{asset?.type?.value}}</div>
                                </div>
                            </div>
                            <div class="item" *ngIf="asset?.class?.value">
                                <div class="img">
                                    <img src="" default="" alt="">
                                </div>
                                <div class="des">
                                    <div class="type_text">CLASS</div>
                                    <div class="value">{{asset?.class?.value}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="attributes_text" [class.hidden]="listAttrs.length === 0">ATTRIBUTES</div>
                        <div *ngIf="listAttrs.length === 0" no_information> {{'common.data.no-information' | translate}}
                        </div>
                        <div style="flex-wrap: wrap;display: flex;">
                            <div *ngFor="let att of listAttrs"
                                style="display: flex;margin-bottom: 15px;justify-content: flex-start; flex:1">
                                <li style="display: block;">
                                    <span class="property-type">{{att?.key}}</span>
                                    <span class="property-type">:</span>
                                    <span class="property-name"> {{att?.value}}</span>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="transactions">
            <div class="title_trans">
                <div class="lineX"></div>
                <div class="trans_text">Asset Activities</div>
                <div class="type_select">
                    <select class="form-select" aria-label="Type" #t (change)="handleSelectType(t.value)">
                        <option selected value="">{{ 'Type'}}</option>
                        <option value="deposit">{{ 'Deposit'}}</option>
                        <option value="withdraw">{{ 'Withdraw'}}</option>
                        <option value="rolling">{{ 'Rolling'}}</option>


                    </select>
                </div>
            </div>
            <wol-table [isLoading]="isTableLoading" [width]="'75vw'" [hideFilter]="true" [hideTitle]="true"
                [keys]="keysTable" [dataSource]="listTransactions">
            </wol-table>
        </div>

        <div class="viewmore">
            <div class="title_view">
                <div class="lineX"></div>
                <div class="view_text">
                    View More Items
                </div>
            </div>
            <div class="card_area" [scrollWindow]="true" [alwaysCallback]="false" (scrolled)="onScroll()" infiniteScroll
                [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="0">
                <wol-card-asset *ngFor="let item of viewMoreAssets" [item]="item"
                    [hiddenAttributes]="['button','status','type']"></wol-card-asset>

                <wol-card-asset *ngFor="let c of itemLoading" [item]="c" [isLoading]="true"></wol-card-asset>
            </div>
            <div *ngIf="!allowQuery"
                style="width:100%;display:flex;align-items: center;justify-content: center;color: #d8e2ea;margin: 40px;">
                {{ 'common.data.data-out-of' | translate }}
            </div>
        </div>
    </div>

</div>