import { Directive,ElementRef, Input } from '@angular/core';
import { TranslateService } from '@wol/ui';
@Directive({
  selector: 'p[check_value]',
  host: {
    // '(error)': 'updateUrl()',
    // '(load)': 'load()',
    // '[src]': 'src',
    // '[style]': 'style',
  },
})
export class WolCheckValueDirective {
  @Input()
  check_value!: string;

  constructor(private el: ElementRef,translateServie : TranslateService) {
    if(!this.el.nativeElement.value) {
      this.el.nativeElement.innerText =this.check_value || translateServie.translate("common.data.no-information")
    }
  }

}
