import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaMaskInpageProvider } from '@metamask/providers';
import { isArrayFull } from '@wol/shared';
import Moralis from 'moralis';
import { CardItem } from '../../components/wol-card-asset/wol-card-asset.component';
import { TransactionCondition } from '../../components/wol-table/wol-table.module';
import { BaseService } from '../base/wol-base.service';
import { EnvironmentService } from '../env/wol-enviroment.service';


@Injectable({
    providedIn: 'root',
})
export class WolAssetDetailService extends BaseService {
    environment: any;
    private web3;
    private ethereum;

    constructor(http: HttpClient, env: EnvironmentService) {
        super(http, env);
        this.environment = env.getEnv() || {};
        // @ts-ignore

        // this.moralis = Moralis;
        this.web3 = new Moralis.Web3;
        this.ethereum = (window as any).ethereum as MetaMaskInpageProvider;
        // @ts-ignore
        const isWeb3Active = Moralis.Web3;
        if (isWeb3Active) {
            console.log("Activated");
        } else {
            console.log("not Activated");
            // @ts-ignore
            Moralis.enableWeb3();
        }
    }

    async getAssetDetailById(condition: any) {

        const result = await this.post('/market/asset', {
            ...condition,
        });
        if (result?.status === 'OK') {
            return this.tranformAssetDetailData(result?.result);
        }
        return;
    }

    async getTransaction(condition: TransactionCondition) {
        return await this.post('/portal/get-transactions', {
            condition
        })
    }

    async getAssetActivitiesByOwner(owner: string, condition: any = {}) {
        console.log(condition)
        return await this.post('/portal/asset-activities', {
            account: owner,
            ...condition
        })
    }

    // async getAssetDetailByOwner(owner: string, round = 0, perRound = 6) {
    async getAssetDetailByOwner(owner: string, game: string, round = 0, perRound = 10, status = '') {
        const result = await this.post('/market/assets/owner', {
            owner,
            round,
            perRound,
            game,
            status
        })
        if (result?.status === 'OK') {
            return this.tranformAssetDetailArray(result?.result);
        }
        return [];
    }

    async getAssetDetailByOwnerCount(owner: string, game: string) {
        const result = await this.post('/market/assets/owner', {
            owner,
            game
        })
        if (result?.status === 'OK') {
            return result?.result?.length
        }
        return 0
    }

    async getAssetDetailViewMore(
        condition: {
            item_category?: string;
            item_type?: string;
            item_class?: string;
        }
    ) {
        const res = await this.post('/market/assets', {
            ...condition,
        });
        if (res?.status === 'OK') {
            if (res?.result?.length !== 0) {
                const data = res?.result.map((item: any) => {
                    return this.tranformAssetDetailData(item);
                });
                return data;
            }
        }
    }

    async count(condition?: any) {
        const cond = Object.keys(condition).length !== 0 ? condition : { status: 'listing' };
        const result = await this.post('/market/assets/count', {
            ...cond
        });
        return result;
    }

    async search() {

    }

    async updateStatusAsset(data: any) {
        return await this.post('/portal/item/update-status', {
            ...data
        });
    }

    async getAssetDetailListing(round = 0, perRound = 12) {
        const result = await this.post('/market/assets/listing', {
            status: 'listing',
            round,
            perRound,
        });
        if (result?.status === 'OK') {
            const data = result?.result.map((item: any) => {
                const dto = this.tranformAssetDetailData(item);
                return dto;
            });
            return data;
        }
        return;
    }

    async updateViewCount(_id: string | any, viewcount: number | any) {
        return await this.post('/market/asset/update-view', {
            _id,
            view: viewcount,
        });
    }

    tranformAssetDetailData(assetData: any) {
        const dto: CardItem = {
            name: assetData?.name,
            game: assetData?.game,
            token_id: assetData?.token_id, //token id (status nft)
            price: assetData?.price,
            image: 'assets/images/' + assetData?.image_url || '',
            description: assetData?.description || '',
            status: assetData?.status,
            type: assetData?.type,
            category: assetData?.category,
            class: assetData?.class,
            asset_id: assetData?.assetId,
            attribute: assetData?.attribute,
            change_at: assetData?.change_at,
            create_at: assetData?.create_at,
            id: assetData?._id,
            view: assetData?.view,
            owner: assetData?.owner,
            attributes : assetData?.attribute
        };
        return dto;
    }

    async getAssetByCondition(
        condition: {
            category?: string;
            type?: string;
            class?: string;
        },
        round = 0,
        perRound = 6
    ) {
        const res = await this.post('/portal/assets/view-more', {
            ...condition,
            round,
            perRound,
        });
        if (res?.status === 'OK') {
            if (res?.result?.length !== 0) {
                const data = res?.result.map((item: any) => {
                    return this.tranformAssetDetailData(item);
                });
                return data;
            }
        }
    }


    tranformAssetDetailArray(data: any[]) {
        if (isArrayFull(data)) {
            const items: any[] = [];
            data.forEach(item => items.push(this.tranformAssetDetailData(item)));
            return items;
        } else {
            return [];
        }
    }

    async saveIPFS(input: { image: string, name: string, description: string, metadata: any }) {
        const file = new Moralis.File("metadata.json", { base64: btoa(JSON.stringify(input)) });
        return await file.saveIPFS();
    }


    async mintItem(data: any) {
        const imageBlob = this.dataURItoBlob(data.base64);
        const imageFileData = new File([imageBlob], data.name);
        const imageFile = new Moralis.File(data.name?.trimStart(), imageFileData);

        let imageIPFS = await imageFile.saveIPFS();
        // @ts-ignore
        const imageURI = await imageIPFS.ipfs();

        const metadata = {
            "name": data?.name,
            "description": data?.description,
            "image": imageURI,
            "metadata": data?.metadata
        }
        const saveAsset = await this.saveIPFS(metadata)
        const txt = await this.mintToken(saveAsset?._url, data);
        return txt;
    }



    async mintToken(_uri: any, data: any) {
        // @ts-ignore
        const encodedFunction = this.web3.eth.abi.encodeFunctionCall({
            name: "mintToken",
            type: "function",
            inputs: [{
                type: 'string',
                name: 'tokenURI'
            }]
        }, [_uri]);


        const transactionParameters = {
            to: this.environment['WOLNFT_TOKEN']?.CONTRACT_ADDRESS,
            from: data?.address,
            data: encodedFunction,
        };
        return await this.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters]
        })
    }

    private dataURItoBlob(dataURI: string) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]); 
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    async getStarterBox(game: string,conditions = {}) {
        const res = await this.post('/portal/box', {
            game,
            ...conditions
        })
        const data = res?.result?.map((result: any) => {
            const d: CardItem = {
                name: result?.name,
                game: result?.game,
                image: 'assets/images/' + result?.image_url,
                price: result?.price,
                category: result?.category,
                class: result?.class,
                type: result?.type,
                change_at: result?.change_at,
                create_at: result?.create_at,
                medal: result?.type?.value ? result?.type?.value[0]?.toUpperCase() : '',
                view: result?.view || 0,
                box_id: result?._id
            }
            return d;
        });


        return data;
    }

    async buyItem(player: { address: string, wol: string, ken: string }, assets: any[]) {
        return await this.post('/portal/asset/buy-item', {
            player,
            assets
        })
    }
}
