import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { WolTabsComponent } from './wol-tabs.component';



@NgModule({
  declarations: [
    WolTabsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    BrowserModule
  ],
  exports: [
    WolTabsComponent
  ]
})
export class WolTabsModule { }
