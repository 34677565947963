import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WolSharedModule } from '../../shared/wol-shared.module';
import { WolCardAssetModule } from '../wol-card-asset/wol-card-asset.module';
import { WolCardFilterComponent } from './wol-card-filter.component';


@NgModule({
    imports: [CommonModule, WolCardAssetModule, WolSharedModule, FormsModule, ReactiveFormsModule,InfiniteScrollModule],
    exports: [WolCardFilterComponent],
    declarations: [WolCardFilterComponent,],
    providers: [],
})
export class WolCardFilterModule { }
