import { Component, Input } from '@angular/core';

// Add icons to the library for convenient access in other components

@Component({
  selector: 'wol-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent {
  @Input() arrow = 'left';
  // faAngleRight = faAngleRight;
  // faAngleLeft = faAngleLeft;
  constructor(
    // library: FaIconLibrary
    ) {
    // library.addIcons(faAngleLeft, faAngleRight);
  }
}
