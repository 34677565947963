/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Renderer2 } from '@angular/core';
import { LINK } from '@wol/shared';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '../../services/base/wol-translate.service';
import { EnvironmentService } from '../../services/env/wol-enviroment.service';

const formData = require('form-data');
const Mailgun = require('mailgun.js');

@Component({
  selector: 'wol-footer',
  templateUrl: './wol-footer.component.html',
  styleUrls: ['./wol-footer.component.scss'],
})
export class WolFooterComponent {
  mailInfo: FormGroup;
  mailgun: any;
  env: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private _env: EnvironmentService,
    private renderer: Renderer2
  ) {
    this.env = _env.getEnv();
    const mailgun = new Mailgun(formData);
    this.mailgun = mailgun.client({
      username: this.env?.MAIL?.USERNAME,
      key: this.env?.MAIL?.KEY,
      public_key: this.env?.MAIL?.PUBLIC_KEY,
    });

    this.mailInfo = this.formBuilder.group({
      mail: new FormControl(null, [
        Validators.maxLength(320),
        Validators.email,
      ]),
    });
  }
  HOME = LINK.HOME;
  LEGENDS_OF_TANK = LINK.LEGENDS_OF_TANK;
  MU_OF_HEROES = LINK.MU_OF_HEROES;
  WOL = LINK.WOL;
  MARKETPLACE = LINK.MARKETPLACE;
  GETTING_STARTED = LINK.GETTING_STARTED;
  NEWS = LINK.NEWS_UPDATE;
  ENCYCLOPEDIA = LINK.ENCYCLOPEDIA;
  TERMS_OF_USE = LINK.TERMS_OF_USE;
  PRIVACY_POLICY = LINK.PRIVACY_POLICY;
  FAQS = LINK.FAQS;
  WHITEPAPER = LINK.WHITEPAPER;
  AUCTION = LINK.AUCTION;

  getTitle(code: string) {
    return this.translateService.translate(code);
  }

  subscribe() {
    // mail input
    const email = this.mailInfo.controls['mail'].value;
    if (email) {
      // check valid address
      if (this.mailInfo.valid) {
        try {
          this.subscribeMail(email);
          this.sendMail(email);
          this.mailInfo.controls['mail'].setValue(null);
          this.toastrService.success(
            this.translateService.translate('messages.ty-for-sub')
          );
        } catch {
          this.toastrService.warning(
            this.translateService.translate('messages.warning')
          );
        }
      } else {
        this.renderer.selectRootElement('#mailInput').focus();
        this.toastrService.warning(
          this.translateService.translate('messages.email-invalid')
        );
      }
    } else {
      this.renderer.selectRootElement('#mailInput').focus();
    }
  }

  subscribeMail(email: string) {
    // get subscribe list
    this.mailgun.lists.members
      .listMembers(this.env.MAIL?.SUBCRIBE_DOMAIN)
      .then((data: any) => {
        if ((data || []).length > 0) {
          const nameList: any[] = [];
          data.forEach((e: any) => {
            // check type
            if (e['name'] === 'subscribe' && e['address']) {
              nameList.push(e.address);
            }
          });
          // check exist
          if (!nameList.includes(email)) {
            // create new
            this.mailgun.lists.members.createMember(
              this.env.MAIL?.SUBCRIBE_DOMAIN,
              {
                address: email,
                name: 'subscribe',
              }
            );
            this.sendMail(email);
          }
        }
      });
  }

  sendMail(mail: string): void {
    try {
      this.mailgun.messages.create(this.env?.MAIL?.DOMAIN, {
        from: this.env?.MAIL?.FROM,
        to: [mail],
        subject: 'Newsletter Subscribe Successfully',
        template: 'subscribe_confirmation',
      });
    } catch (e) {
      console.error(e);
    }
  }
}
