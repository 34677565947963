import { environment } from './../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { KEYS, LangDto, LINK } from '@wol/shared';
import { TranslateService } from '@wol/ui';
import { ToastrService } from 'ngx-toastr';
import * as copy from 'copy-to-clipboard';

@Component({
  selector: 'wol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() opened = false;
  @Output() clicked = new EventEmitter();
  languageForm: FormGroup;
  pageForm: FormGroup;
  // faBars = faBars;
  // faTimes = faTimes;
  isShow = true;
  pageKey = 0;

  WHITEPAPER = LINK.WHITEPAPER;
  LEGENDS_OF_TANK = LINK.LEGENDS_OF_TANK;
  MU_OF_HEROES = LINK.MU_OF_HEROES;
  WIKI = LINK.WIKI;
  NEWS = LINK.NEWS;
  MARKETPLACE = LINK.MARKETPLACE;

  MAIN_ADDRESS = environment.COMMON.MAIN_ADDRESS;
  SHORT_ADDRESS = '';

  languages: LangDto[] = [
    {
      id: 'en_US',
      name: 'EN',
    },
    {
      id: 'zh_CN',
      name: 'CN',
    },
    {
      id: 'ja_JP',
      name: 'JP',
    },
    {
      id: 'ko_KP',
      name: 'KP',
    },
    {
      id: 'ru_RU',
      name: 'RU',
    },
    {
      id: 'th_TH',
      name: 'TH',
    },
    {
      id: 'tl_PH',
      name: 'PH',
    },
    {
      id: 'vi_VN',
      name: 'VN',
    }
  ];

  pages: string[] = ['Whitepaper', 'Tokenomic', 'Marketplace', 'Wiki', 'News'];
  default = { id: 'en_US', name: 'EN' };

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService,
    public router: Router,
    // library: FaIconLibrary
  ) {
    const currentUrl = location.href;
    if ((currentUrl || '').includes(KEYS.TOKENOMIC)) {
      this.pageKey = 2;
    }
    // library.addIcons(faBars, faBars);
    this.languageForm = new FormGroup({
      language: new FormControl(this.default),
    });
    this.pageForm = new FormGroup({
      page: new FormControl(null),
    });
    const currentLang = localStorage.getItem('language');
    if (currentLang) {
      const index = this.languages.findIndex((e) => e.id === currentLang);
      if (index !== -1) {
        this.languageForm.controls['language'].setValue(this.languages[index], {
          onlySelf: true,
        });
      } else {
        this.languageForm.controls['language'].setValue(this.default, {
          onlySelf: true,
        });
      }
    } else {
      this.languageForm.controls['language'].setValue(this.default, {
        onlySelf: true,
      });
    }
  }

  ngOnInit(): void {
    this.languageForm.valueChanges.subscribe((data) => {
      localStorage.setItem('language', data.language?.id || 'en_US');
      this.translateService.language.next(data.language || this.default);
    });
    this.SHORT_ADDRESS = this.shortAddress();
  }

  shortAddress(): string {
    return new String(this.MAIN_ADDRESS)
      .slice(0, 5)
      .concat('...')
      .concat(new String(this.MAIN_ADDRESS).slice(-3));
  }
  joinTestnet() {
    window.open(LINK.PORTAL)
  }

  navigate(key: number): void {
    switch (key) {
      case 0:
        this.pageKey = 0;
        this.router.navigate(['/']);
        this.scrollTop();
        break;
      case 1:
        location.href = this.WHITEPAPER;
        this.pageKey = 1;
        break;
      case 2:
        this.pageKey = 2;
        this.router.navigate(['/tokenomic']);
        this.scrollTop();
        break;
      case 3:
        location.href = this.MARKETPLACE;
        this.pageKey = 3;
        break;
      case 4:
        location.href = this.WIKI;
        this.pageKey = 4;
        break;
      case 5:
        location.href = this.NEWS;
        this.pageKey = 4;
        break;
    }
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  toggle() {
    this.opened = !this.opened;
    this.clicked.emit(this.opened);
  }

  copyAddress() {
    copy(this.MAIN_ADDRESS);
    this.toastrService.success(this.translateService.translate('messages.copied'));
  }

  close() {
    this.isShow = false;
  }
}
