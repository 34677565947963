import { Component, OnInit } from '@angular/core';
import { LINK } from '@wol/shared';

@Component({
  selector: 'wol-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  LEGENDS_OF_TANK = LINK.LEGENDS_OF_TANK;
  constructor() { }

  ngOnInit(): void {
  }

  href() {
    location.href = this.LEGENDS_OF_TANK;
  }

}
