import { Component, OnInit } from '@angular/core';
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
// import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'wol-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss']
})
export class ProfitComponent implements OnInit {
  // faAngleRight = faAngleRight;
  constructor(
    // library: FaIconLibrary
    ) {
    // library.addIcons(faAngleRight);
  }

  ngOnInit(): void {
  }

}
