import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "../services/base/wol-translate.service";

@Injectable(
  {
    providedIn :'root'
  }
)
@Pipe({
    name: 'translate',
    pure: false
})
export class WolTranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
     return this.translateService.translate(value);
  }
}