import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wol-announcement-popup',
  templateUrl: './announcement-popup.component.html',
  styleUrls: ['./announcement-popup.component.scss'],
})
export class AnnouncementPopupComponent {
  LINK = 'https://t.me/battlecityhqofficial/50';
  @Output() closeDialog = new EventEmitter<boolean>();

  close(): void {
    this.closeDialog.emit(true);
  }
}
