export enum COMMON {
  BATTLE_CITY_CONTRACT_ADDRESS = '0x5eeb28b5aef44b6664b342d23b1aadce84196386',
}

export enum KEYS {
  TOKENOMIC = 'tokenomic',
  PRIVATE_SALE = 'private-sale'
}

export enum LINK {
  HOME = 'https://battlecity.io/',
  TOKENOMIC = 'https://battlecity.io/tokenomic',
  LEGENDS_OF_TANK = 'https://tank.battlecity.io/',
  MU_OF_HEROES = 'https://mu.battlecity.io/',
  WIKI = 'https://wiki.battlecity.io/',
  NEWS = 'https://battlecityio.substack.com/',
  WOL = 'https://bscscan.com/token/0x5eeb28b5aef44b6664b342d23b1aadce84196386',
  MARKETPLACE = 'https://marketplace.battlecity.io/',
  GETTING_STARTED = 'https://wiki.battlecity.io/getting-started',
  NEWS_UPDATE = 'https://battlecityio.substack.com/',
  ENCYCLOPEDIA = 'https://wiki.battlecity.io/',
  TERMS_OF_USE = 'https://terms.battlecity.io/',
  PRIVACY_POLICY = 'https://privacy.battlecity.io/',
  FAQS = 'https://wiki.battlecity.io/faqs',
  WHITEPAPER = 'https://whitepaper.battlecity.io/',
  AUCTION = 'https://marketplace.battlecity.io/',
  SUBSCRIBE = 'https://battlecityio.substack.com/',
  METAMASK = 'https://metamask.io/download.html',
  AWS = 'https://aws.amazon.com/what-is-aws/',
  BINANCE = 'https://www.binance.com/en/about',
  ANTS = '#',
  BLG = 'https://bizlegalgroup.com/',
  UNICRYPT = 'https://unicrypt.network/',
  HACKEN = 'https://hacken.io/',
  TELEGRAM = 'https://t.me/battlecityhqofficial',
  DISCORD = 'https://discord.com/invite/PcZJG8h9SK',
  FACEBOOK = 'https://www.facebook.com/battlecity.io',
  YOUTUBE = 'https://www.youtube.com/c/BattleCityHQ',
  TWITTER = 'https://twitter.com/BattleCityHQ',
  MEDIUM = 'https://battlecityhq.medium.com/',
  PORTAL = 'https://portal.stg.battlecity.io/',
  BITKEEP = 'https://bitkeep.com/en/dapp'
}


export enum NFTItemStatus {
  'Hot Deals' = 'Hot Deals',
  'Buy Now' = 'Buy Now',
  'On Auction' = 'On Auction',
  'New' = 'New',
  'Recently Auctioning' = 'Recently Auctioning',
}


export enum ItemStatus {
  HOT_DEAL = 'hot_deal',
  NEW = 'new'
}

export enum STATUS {
  NFT = 'NFT',
  MINTING = 'Minting',
  IN_GAME = 'In Game'
}


export enum SortByItem {
  MOST_VIEWED = 'most_viewed',
  RECENTLY_CREATED = 'recently_created',
  RECENTLY_UPDATED = 'recently_updated',
  LOW_PRICE_INCREASE = 'low_price_increase',
  HIGH_PRICE_INCREASE = 'high_price_increase',
}


export enum FILTERS_FORM {
  SORT_BY = 'sort_by',
  CATEGORIES = 'categories',
  ALL_GAMES = 'all_games',
  STATUS = 'all_status'
}

export enum TX_QUEUE_STATUS {
  CREATED = 'created',
  PROCESSING = 'processing',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
  APPROVED = 'approved', // withdraw
  REJECTED = 'rejected', // withdraw
}