import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable(
  {
    providedIn: 'root'
  }
)
@Pipe({
  name: 'wol_currency',
})
export class WolCurrencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return  + value * 0.001 + ' BNB';
  }
}
