import { AppState } from ".";
import { SearchActionTypes } from "../actions/wol-search.action";

export interface SearchState {
  searchTerm: string;
}

const initialState = {
  searchTerm: ''
}


export const SearchReducer = (state = initialState, action: { type: string, payload?: any }) => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_BAR:
      return {
        ...state,
        searchTerm: action.payload
      }
    default:
      return state;
  }
}

export const getSearchTerm = (state: AppState) => state.searchReducer.searchTerm;
