import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-coin',
  templateUrl: './coin.component.html',
  styleUrls: ['./coin.component.scss']
})
export class CoinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
