import { Action } from '@ngrx/store';

// [Filter Feature]
// - navbar-filter-items-game
export enum CardActionTypes {
  CARD_LOADING = '[CARD] CARD LOADING',
  CARD_UN_LOADING = '[CARD] CARD UNLOADING',
  CARD_LOAD_MORE = '[CARD] LOAD MORE',
  CARD_UN_LOAD_MORE = '[CARD] UN LOAD MORE',
  CARD_LOAD_MORE_DATA = '[CARD] CARD_LOAD_MORE_DATA',
}

export class CARD_LOADING implements Action {
  readonly type = CardActionTypes.CARD_LOADING;
}

export class CARD_UN_LOADING implements Action {
  readonly type = CardActionTypes.CARD_UN_LOADING;
}

export class CARD_LOAD_MORE implements Action {
  readonly type = CardActionTypes.CARD_LOAD_MORE;
}

export class CARD_UN_LOAD_MORE implements Action {
  readonly type = CardActionTypes.CARD_UN_LOAD_MORE;
}

export class CARD_LOAD_MORE_DATA implements Action {
  readonly type = CardActionTypes.CARD_LOAD_MORE_DATA;
  public payload: any[];
  constructor(_payload: any[]) {
    this.payload = _payload;
  }
}

export type CardActionsUnion =
  | CARD_LOADING
  | CARD_UN_LOADING
  | CARD_LOAD_MORE
  | CARD_UN_LOAD_MORE
  | CARD_LOAD_MORE_DATA;
