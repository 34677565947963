<div class="card_asset_wrapper">
    <!-- <p style="color: #fff;">{{item?.asset_id}}</p> -->
    <div class="status" status="{{item?.status}}" *ngIf="checkShowValue(item?.status,'status')">{{item?.status}}</div>
    <div class=" status skeleton-box" style="width: 40px;height: 14px;border-radius: 5px;" *ngIf="isLoading"></div>

    <div class="view" *ngIf="checkShowValue(item?.view,'view')">
        <img src="assets/images/bi_eye.svg" width="16" height="16" alt="">
        <span>{{item?.view}}</span>
    </div>
    <div class="view skeleton-box" style="width: 40px;border-radius: 5px;" *ngIf="isLoading">
        <!-- <img src="/assets/images/bi_eye.svg" width="16" height="16" alt="">
        <span>{{item?.view}}</span> -->
    </div>

    <div class="image" *ngIf="!isLoading" (click)="hanleClick()">
        <img default="" [id]="'imgElement'" src="{{item?.image}}" alt="">
    </div>

    <div class="image " *ngIf="isLoading">
        <!-- <img default="" src="{{item?.image}}" alt=""> -->
        <div class="skeleton-box" style="height: 70%;width: 90%;
        margin: auto;"></div>
    </div>
    <div class="information" (click)="hanleClick()">
        <div class="sumary">
            <div style="display: flex;flex-direction: column;">
                <div class="sub_name " *ngIf="checkShowValue(item?.type?.value,'type')">{{item?.type?.value}} </div>
                <div class="name" *ngIf="!isLoading">{{item?.name}}</div>
                <div class="name skeleton-box" style="height: 20px;margin-bottom: 5px; width: 100px;" *ngIf="isLoading">
                </div>
                <div class="price_box" *ngIf=" checkShowValue(item?.price,'price')">
                    <span class="price ">{{item?.price}} WOL
                        <span class="usd ">{{item?.price | wol_currency}}</span>
                    </span>
                </div>
                <div class="price_box skeleton-box" *ngIf="isLoading">

                </div>
            </div>
            <div class="medal" *ngIf="!isLoading">
                <span medal="{{item?.medal}}">
                    {{item?.medal}}
                </span>
            </div>

            <div class="medal skeleton-box" *ngIf="isLoading">
                <span medal="{{item?.medal}}">
                </span>
            </div>
        </div>
        <div class="hint">
            <div class="hint1" *ngIf=" checkShowValue(item?.game?.value,'game')">
                {{item?.game?.value}}
            </div>
            <div class="hint1 skeleton-box" style="width: 80px;" *ngIf="isLoading">
            </div>
            <div class="hint2" *ngIf="checkShowValue(item?.token_id,'token_id')">
                #{{item?.token_id}}
            </div>
            <div class="hint2 skeleton-box" style="width: 40px;" *ngIf="isLoading">

            </div>
        </div>
    </div>
    <div class="buy_now" *ngIf="!isLoading && !hiddenAttributes.includes('button')" (click)="handleClickButtonCard()">
        <span [style.color]="colorBtn">{{buttonName}}</span>
    </div>
    <div class="buy_now " *ngIf="isLoading && !hiddenAttributes.includes('button')">
        <span class="skeleton-box" style="width: 100px;" [style.color]="colorBtn"></span>
    </div>
</div>