import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'wol-button',
    templateUrl: 'wol-button.component.html',
    styleUrls: ['./wol-button.component.scss']
})

export class WolButtonComponent implements OnInit {
    @Input() text?: string = 'default';
    @Input() background?: string = '#333';
    @Input() color?: string = '#fff';
    @Input() margin: number = 21;
    constructor() { }

    ngOnInit() { }
}