/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
// import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
// import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  isStringFull,
  MailAddressDto,
  PRIVATE_SALE_ADDRESS,
} from '@wol/shared';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  WolBaseComponent,
  BaseService,
  EnvironmentService,
  TranslateService,
} from '@wol/ui';
import * as copy from 'copy-to-clipboard';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { environment } from './../../../environments/environment';
const formData = require('form-data');
const Mailgun = require('mailgun.js');
const { GoogleSpreadsheet } = require('google-spreadsheet');

@Component({
  selector: 'wol-private-sale-popup',
  templateUrl: './private-sale-popup.component.html',
  styleUrls: ['./private-sale-popup.component.scss'],
})
export class PrivateSalePopupComponent
  extends WolBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() isPopup = true;
  @Output() closeDialog = new EventEmitter<boolean>();
  COUNT_DOWN_START = environment.PRIVATE_SALE.START;
  COUNT_DOWN_END = environment.PRIVATE_SALE.END;
  COUNT_DOWN = 0;
  MAIN_ADDRESS = PRIVATE_SALE_ADDRESS;
  // faTimes = faTimes;
  // faCopy = faCopy;
  isShow = false;
  isSubmit = false;
  step2 = false;
  registerForm: FormGroup;
  mailgun: any;
  env: any;
  DAYS = '00';
  HOURS = '00';
  MINUTES = '00';
  SECONDS = '00';
  docs: any;

  error = {
    mail: '',
    address: '',
  } as any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    // library: FaIconLibrary,
    baseService: BaseService,
    _env: EnvironmentService
  ) {
    super(baseService, _env);
    // library.addIcons(faTimes);
    //Init mailgun
    const mailgun = new Mailgun(formData);
    this.mailgun = mailgun.client({
      username: environment.MAIL.USERNAME,
      key: environment.MAIL.KEY,
      public_key: environment.MAIL.PUBLIC_KEY,
    });
    //Input form
    this.registerForm = this.formBuilder.group({
      mail: new FormControl(null, [
        Validators.email,
        Validators.required,
        Validators.maxLength(400),
      ]),
      address: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
  }

  //Onload check error
  onInput(type: string, value: string) {
    if (!value?.trim()) {
      this.error[type] = this.translateService
        .translate('messages.is-required')
        .replace('{0}', type);
    } else if (
      type === 'mail' &&
      this.registerForm.controls['mail'].errors?.email
    ) {
      this.error[type] = this.translateService.translate(
        'messages.email-invalid'
      );
    } else {
      this.error[type] = '';
    }
  }

  ngOnInit(): void {
    this.step2 = false;
    const now = new Date().getTime();
    if (now > this.COUNT_DOWN_START) {
      this.step2 = true;
    }

    if (this.isPopup && this.step2) {
      this.COUNT_DOWN = this.COUNT_DOWN_END;
      this.isShow = true;
    } else if (this.isPopup) {
      this.COUNT_DOWN = this.COUNT_DOWN_START;
    } else if (this.step2) {
      this.COUNT_DOWN = this.COUNT_DOWN_END;
    } else {
      this.router.navigate(['/']);
    }

    const distance = this.COUNT_DOWN - now;
    if (distance < 0 && !this.isPopup) {
      this.router.navigate(['/']);
    }
    if (!this.isPopup) {
      this.initGapis();
    }
    this.convertTime();
  }

  //init google sheets api
  async initGapis() {
    try {
      this.docs = new GoogleSpreadsheet(environment.MAIL.SPREAD_SHEET_ID);
      this.docs.useServiceAccountAuth(environment.MAIL.CREDENTIALS);
    } catch (e) {
      console.error(e);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.isPopup) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 0);
  }

  register(): void {
    this.closeDialog.emit(true);
    this.router.navigate(['/private-sale']);
  }

  copy(): void {
    copy(this.MAIN_ADDRESS);
    this.toastrService.success(
      this.translateService.translate('messages.copied')
    );
  }

  //event submit
  async submit(): Promise<void> {
    if (this.registerForm.valid) {
      if (this.isSubmit) {
        return;
      }
      this.isSubmit = true;
      const mailAddress: MailAddressDto = this.getFormValue();
      // check duplicate address
      const isStringFull = this.isValidValue();
      if (!isStringFull) {
        this.isSubmit = false;
        return;
      }
      const isError = await this.isDuplicateAddress(mailAddress.address);
      if (isError) {
        this.error['address'] = this.translateService.translate(
          'messages.duplicate-address'
        );
        this.renderer.selectRootElement('#address').focus();
        this.isSubmit = false;
        return;
      }
      try {
        // send mail
        this.sendMail(mailAddress);
      } catch (e) {
        this.isSubmit = false;
      }
      try {
        // add values to gg sheets
        await this.appendInfo(mailAddress);
        this.toastrService.success(
          this.translateService.translate('messages.ty-for-reg')
        );
      } catch (e) {
        this.isSubmit = false;
        this.toastrService.warning(
          this.translateService.translate('messages.warning')
        );
      }
      setTimeout(() => {
        this.registerForm.reset();
        this.isSubmit = false;
      }, 200);
    } else {
      const mail = this.registerForm.controls['mail'].value;
      const address = this.registerForm.controls['address'].value;
      if (!mail) {
        this.renderer.selectRootElement('#mail').focus();
      } else if (!address) {
        this.renderer.selectRootElement('#address').focus();
      } else {
        this.renderer.selectRootElement('#mail').focus();
      }
    }
  }

  isValidValue() {
    const mail = this.registerForm.controls['mail'].value;
    const address = this.registerForm.controls['address'].value;
    return isStringFull(mail?.trim()) && isStringFull(address?.trim());
  }

  getFormValue(): MailAddressDto {
    const mail = this.registerForm.controls['mail'].value;
    const address = this.registerForm.controls['address'].value;
    return {
      mail: mail.trim(),
      address: address.trim(),
      time: new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
    } as MailAddressDto;
  }
  async isDuplicateAddress(address: string): Promise<boolean> {
    try {
      // const res = await this.gapiGetList();
      const addressList = await this.getAddressList();
      return addressList.includes(address.trim());
    } catch {
      return false;
    }
  }

  sendMail(
    mailAddress: MailAddressDto,
  ): void {
    try {
      this.mailgun.messages.create(environment.MAIL.DOMAIN, {
        from: environment.MAIL.FROM,
        to: [mailAddress.mail],
        subject: 'Private Sale 1 Registration Confirmation',
        template: 'registration_confirmation'
      });
    } catch (e) {
      console.error(e);
    }
  }

  close(): void {
    this.closeDialog.emit(true);
  }

  //handler time countdown
  convertTime(): void {
    const countDown = setInterval(() => {
      // Get todays date and time
      const now = new Date().getTime();

      // Find the distance between now an the count down date
      const distance = this.COUNT_DOWN - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor((distance % 2073600000) / 86400000);
      const hours = Math.floor((distance % 86400000) / 3600000);
      const minutes = Math.floor((distance % 3600000) / 60000);
      const seconds = Math.floor((distance % 60000) / 1000);

      // Check if the count down is over
      if (distance > 0) {
        this.DAYS = this.getFomrmat(days);
        this.HOURS = this.getFomrmat(hours);
        this.MINUTES = this.getFomrmat(minutes);
        this.SECONDS = this.getFomrmat(seconds);
      } else {
        clearInterval(countDown);
        if (this.isPopup) {
          this.isShow = true;
        }
      }
    }, 1000);
  }

  getFomrmat(num: number): string {
    return ('0' + num).slice(-2);
  }

  getTitle(): string {
    if (this.isPopup && !this.step2) {
      return this.translateService.translate('private-sale.time-start');
    } else {
      return this.translateService.translate('private-sale.time-end');
    }
  }

  async getAddressList(): Promise<string[]> {
    await this.docs.loadInfo();
    const sheet = this.docs.sheetsByIndex[0];
    const rows = await sheet.getRows();
    const arrAddress: string[] = [];
    (rows || []).forEach((row: any) => {
      if (row && row._rawData[1]) {
        arrAddress.push(row._rawData[1]);
      }
    });
    return arrAddress;
  }

  async appendInfo(info: MailAddressDto): Promise<void> {
    await this.docs.loadInfo();
    const sheet = this.docs.sheetsByIndex[0];
    const result = await sheet.addRow([info.mail, info.address, info.time]);
    return result;
  }
}
