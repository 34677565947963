<div class="area" [ngStyle]="{ 'min-height': '31.625rem' }">
  <div class="header">
    <div class="close" (click)="close()">
      <i class="fa-solid fa-xmark"></i>
    </div>
    <img
      style="width: 5.3125rem; height: 4.25rem; margin-right: 1.75rem"
      class="icon"
      src="assets/images/loa.png"
    />
    <div>
      <div class="h-text" style="margin-bottom: 0.625rem">March 2022</div>
      <div class="h-text">Announcenment</div>
    </div>
  </div>
  <div class="footer">
    <div class="line" style="margin-bottom: 0.9375rem">
      <img class="icon tick" src="assets/images/tick.svg" />
      <div class="line-text-1">Schedule for the MU of Heroes testnet.</div>
    </div>

    <div class="line" style="margin-bottom: 0.9375rem">
      <img class="icon tick" src="assets/images/tick.svg" />
      <div class="line-text-1">
        The newest update for the Q2/2022 roadmap of our MU of Heroes.
      </div>
    </div>
<!-- 
    <div class="line" style="padding-left: 1.5rem; margin-bottom: 0.375rem">
      <div class="line-text-2">MU of Heros Roadmap</div>
      <i
        class="fa-solid fa-angle-right"
        style="color: #ff36d3; margin-left: 0.5rem; margin-top: 0.3125rem"
      ></i>
    </div>
    <div class="line" style="padding-left: 1.5rem; margin-bottom: 1.8125rem">
      <div class="line-text-2">Legends of Tanks Roadmap</div>
      <i
        class="fa-solid fa-angle-right"
        style="color: #ff36d3; margin-left: 0.5rem; margin-top: 0.3125rem"
      ></i>
    </div> -->

    <div class="line" style="margin-bottom: 0.375rem">
      <img class="icon tick" src="assets/images/tick.svg" />
      <div class="line-text-1">
        The first WOL tokens will be distributed to investors on June 1st, 2022
      </div>
    </div>

    <div class="line" style="padding-left: 1.5rem; margin-bottom: 1.125rem">
      <div class="line-text-3">
        Based on our vesting schedule with past IDOs, which date is expected to
        <br />
        be around March 2022.
      </div>
    </div>

    <div class="line" style="margin-bottom: 0.9375rem">
      <img class="icon tick" src="assets/images/tick.svg" />
      <div class="line-text-1">
        For the investors who have lost faith in the WOL token and
        <span class="pink">BattleCity.io</span> project.
      </div>
    </div>

    <div class="line" style="padding-left: 1.5rem; margin-bottom: 1.125rem">
      <div class="line-text-3">
        Kindly shoot us an email at
        <span class="pink">hello@battlecity.io</span> with your request
        including <br />
        TX and your wallet address.
      </div>
    </div>

    <div class="submit">
      <button type="button" class="button">
        <a class="text-href" [href]="LINK | safeUrl" target="_blank">
        Read the detailed announcement
        </a>
      </button>

      <div class="line-text-4">
        We're looking forward to exceeding enormous benchmarks in 2022.
        Godspeed!
      </div>
    </div>
  </div>
</div>
