import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Cookie } from "ng2-cookies/ng2-cookies";


@Injectable()
export class WolAuthGuardService implements CanActivate {

    constructor(private _router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const token = Cookie.get('accessToken');
        //check some condition  
        if (!token) {
            if (location.pathname !== '/') {
                this._router.navigateByUrl('/')
            }
            return false;
        }
        return true;
    }

}
