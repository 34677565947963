<ng-sidebar-container>
  <ng-sidebar
    style="width: 50px"
    [opened]="opened"
    [closeOnClickBackdrop]="true"
    [closeOnClickOutside]="true"
    (onClosed)="onClosed()"
  >
    <div class="sidebar-content">
      <a
        [href]="WHITEPAPER | safeUrl"
        (click)="navigate(-1)"
        target="_blank"
        class="sidebar-text"
        [ngStyle]="{ color: pageKey === 1 ? '#FF36D3' : 'white' }"
        >{{ 'common.whitepaper' | translate }}</a
      >
      <a
        (click)="navigate(2)"
        class="sidebar-text"
        [ngStyle]="{ color: pageKey === 2 ? '#FF36D3' : 'white' }"
        >{{ 'common.tokenomic' | translate }}</a
      >
      <a
        [href]="MARKETPLACE | safeUrl"
        (click)="navigate(-1)"
        class="sidebar-text"
        [ngStyle]="{ color: pageKey === 3 ? '#FF36D3' : 'white' }"
        >{{ 'common.marketplace' | translate }}</a
      >
      <a
        [href]="WIKI | safeUrl"
        (click)="navigate(-1)"
        class="sidebar-text"
        target="_blank"
        [ngStyle]="{ color: pageKey === 4 ? '#FF36D3' : 'white' }"
        >{{ 'common.wiki' | translate }}</a
      >
      <a
        [href]="NEWS | safeUrl"
        (click)="navigate(-1)"
        class="sidebar-text"
        target="_blank"
        [ngStyle]="{ color: pageKey === 4 ? '#FF36D3' : 'white' }"
        >{{ 'common.news' | translate }}</a
      >
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <wol-header [opened]="opened" (clicked)="toogle($event)"></wol-header>
    <router-outlet></router-outlet>
    <wol-footer></wol-footer>
    <wol-top-button></wol-top-button>
  </div>
</ng-sidebar-container>

<div class="container__loading" id="app_loading" *ngIf="isAppLoading">
  <div class="loadingio-spinner-spin-ibku8ak7k2o">
    <div class="ldio-qpgi1jojzu">
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
      <div><div></div></div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="privateSaleDisplay" [modal]="true">
<wol-private-sale-popup (closeDialog)="closeDialog($event)"></wol-private-sale-popup>
</p-dialog>

<p-dialog [(visible)]="announcementDisplay" [modal]="true">
  <wol-announcement-popup (closeDialog)="closeDialog($event)"></wol-announcement-popup>
  </p-dialog>