import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'wol-top-button',
  templateUrl: './wol-top-button.component.html',
  styleUrls: ['./wol-top-button.component.scss'],
})
export class WolTopButtonComponent {
  // faAngleUp = faAngleUp;
  isShow = false;
  constructor(
    // library: FaIconLibrary
    ) {
    // library.addIcons(faAngleUp);
  }

  goTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  @HostListener('window:scroll', ['$event']) 
    checkPosition() {
      const position = window.pageYOffset;
      if (position && position > 10) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
}
