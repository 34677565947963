import { KeyValueDto } from '@wol/shared';
import { AppState } from '.';
import { FilterActionTypes, FILTER_RESET_TYPE } from '../actions/wol-filter.action';

export interface KeyValueCheckBox {
  key: string;
  value: string;
  checked: boolean;
}

export interface PriceRange {
  value?: number;
  highValue?: number;
  type?: string;
}

export interface FilterState {
  filterGameState?: KeyValueCheckBox[] | any;
  filterSortBy?: KeyValueDto | any;
  filterCategory?: KeyValueCheckBox[] | any;
  filterPrice?: PriceRange;
  filterStatus?: string[] | any;
  filterCardStatus?: string[] | any;
  filterCardGame?: KeyValueDto | any,
  filterCardCategory?: KeyValueDto | any,
}

const initialState: FilterState = {
  filterGameState: [],
  filterSortBy: {},
  filterCategory: [],
  filterPrice: {},
  filterStatus: [],
  filterCardStatus: [],
  filterCardGame: {},
  filterCardCategory: {}
};

export const filterReducer = (
  state = initialState,
  action: { type?: string; payload?: any }
) => {
  switch (action.type) {
    case FilterActionTypes.FILTER_GAME:
      const modif = (action.payload || []).filter((f: any) => f.checked);
      return {
        ...state,
        filterGameState: modif,
      };
    case FilterActionTypes.FILTER_SORTBY:

      return {
        ...state,
        filterSortBy: {
          key: action.payload?.code,
          value: action.payload?.value,
          tabName: action.payload?.tab
        },
      };

    case FilterActionTypes.FILTER_CATEGORY:
      const modi = (action.payload || []).filter((f: any) => f.checked);
      return {
        ...state,
        filterCategory: modi,
      };
    case FilterActionTypes.FILTER_PRICE:
      return {
        ...state,
        filterPrice: action.payload,
      };
    case FilterActionTypes.FILTER_STATUS:
      return {
        ...state,
        filterStatus: action.payload,
      };

    case FilterActionTypes.FILTER_CARD_STATUS:
      const find = state.filterCardStatus.filter((f: any) => f?.tab !== action.payload?.tab)
      return {
        ...state,
        filterCardStatus: [...find, action.payload],
      };
    case FilterActionTypes.FILTER_CARD_GAME:
      return {
        ...state,
        filterCardGame: action.payload,
      };
    case FilterActionTypes.FILTER_CARD_CATEGORIES:
      return {
        ...state,
        filterCardCategory: action.payload,
      };
    case FilterActionTypes.FILTER_RESET:
      return {
        ...state,
        filterGameState: [],
        filterSortBy: {},
        filterCategory: [],
        filterStatus: [],
        filterPrice : {}
      };
    default:
      return state;
  }
};

// selector

export const getFilterGameState = (state: AppState) =>
  state.filterConditions.filterGameState;

export const getFilterSortBy = (state: AppState) =>
  state.filterConditions.filterSortBy;

export const getFilterCategory = (state: AppState) =>
  state.filterConditions.filterCategory;

export const getFilterStatusState = (state: AppState) =>
  state.filterConditions.filterStatus;

export const getFilterCardStatusState = (state: AppState) =>
  state.filterConditions.filterCardStatus;

export const getFilterPriceState = (state: AppState) => state.filterConditions.filterPrice;

export const getFilterState = (state: AppState) => state.filterConditions;
