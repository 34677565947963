import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KEYS, LINK } from '@wol/shared';
import { environment } from '../environments/environment';
import { WolBaseComponent, BaseService } from '@wol/ui';
import { EnvironmentService } from '@wol/ui';
@Component({
  selector: 'wol-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends WolBaseComponent implements OnInit {
  MARKETPLACE = environment.COMMON.MARKETPLACE;
  TIME_TO_END = environment.PRIVATE_SALE.END;
  WHITEPAPER = LINK.WHITEPAPER;
  WIKI = LINK.WIKI;
  NEWS = LINK.NEWS;
  opened = false;
  isAppLoading = true;
  pageKey = 0;
  privateSaleDisplay = false;
  announcementDisplay = false;
  currentUrl = '';

  constructor(
    public router: Router,
    baseService: BaseService,
    _env: EnvironmentService
  ) {
    super(baseService, _env);

    const localStorageLanguages = localStorage.getItem('language');
    if (!localStorageLanguages) {
      localStorage.setItem('language', 'en_US');
    }
    this.currentUrl = location.href;
    if ((this.currentUrl || '').includes(KEYS.TOKENOMIC)) {
      this.pageKey = 2;
    }
  }
  
  ngOnInit(): void {
    setTimeout(() => {
      this.isAppLoading = false;
      setTimeout(() => {
        if (!(this.currentUrl || '').includes(KEYS.PRIVATE_SALE)) {
          const now = new Date().getTime();
          const distance = this.TIME_TO_END - now;
          if (distance > 0) {
            this.privateSaleDisplay = true;
          }
        }
        // this.announcementDisplay = true;
      }, 500);
    }, 1500);
  }
  
  closeDialog(event: boolean) {
    if (event) {
      this.privateSaleDisplay = false;
      this.announcementDisplay = false;
    }
  }

  toogle(event: boolean): void {
    this.opened = event;
  }

  onClosed() {
    this.opened = false;
  }

  navigate(key: number): void {
    this.opened = false;
    switch (key) {
      case 0:
        this.pageKey = 0;
        this.router.navigate(['/']);
        this.scrollTop();
        break;
      case 1:
        this.pageKey = 1;
        break;
      case 2:
        this.pageKey = 2;
        this.router.navigate(['/tokenomic']);
        this.scrollTop();
        break;
      case 3:
        location.href = this.MARKETPLACE;
        this.pageKey = 3;
        break;
      case 4:
        location.href = this.NEWS;
        this.pageKey = 4;
        break;
    }
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }
}
