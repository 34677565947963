import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WolImagePreloadDirective } from '../directives/wol-default-image.directive';
import { WolMedalDirective } from '../directives/wol-medal.directive';
import { WolStatusDirective } from '../directives/wol-status.directive';
import { WolCurrencyPipe } from '../pipes/wol-currency.pipe';
import { WolTranslatePipe } from '../pipes/wol-translate.pipe';
import { WolSafeUrlPipe } from '../pipes/wol-safeUrl.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WolCheckEthAddressDirective } from "../directives/wol-check-eth-address.directive";
import { WolCheckBalanceAddressDirective } from "../directives/wol-check-balance-address.directive";
import { WolConfirmModule } from '../components/wol-confirm/wol-confirm.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { WolClickoutDirective } from '../directives/wol-clickout.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WolChipModule } from '../components/wol-chip/wol-chip.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    WolConfirmModule,
    NgbModule,
    InfiniteScrollModule,
    ShareButtonsModule,
    ShareIconsModule,
    ShareButtonsPopupModule,
    FontAwesomeModule,
    WolChipModule
  ],

  exports: [
    WolTranslatePipe,
    WolCurrencyPipe,
    WolSafeUrlPipe,
    WolStatusDirective,
    WolMedalDirective,
    CommonModule,
    WolImagePreloadDirective,
    WolCheckEthAddressDirective,
    WolCheckBalanceAddressDirective,
    DatePipe,
    WolConfirmModule,
    NgbModule,
    InfiniteScrollModule,
    ShareButtonsModule,
    ShareIconsModule,
    ShareButtonsPopupModule,
    FontAwesomeModule,
    WolClickoutDirective,
    WolChipModule
  ],
  declarations: [
    WolTranslatePipe,
    WolCurrencyPipe,
    WolSafeUrlPipe,
    WolStatusDirective,
    WolMedalDirective,
    WolImagePreloadDirective,
    WolCheckEthAddressDirective,
    WolCheckBalanceAddressDirective,
    WolClickoutDirective
  ],
  providers: [DatePipe],
})
export class WolSharedModule { }
