import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as copy from 'copy-to-clipboard';
import { LINK } from '@wol/shared';
import { TranslateService } from '@wol/ui';

@Component({
  selector: 'wol-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  MU_OF_HEROES = LINK.MU_OF_HEROES;
  MAIN_ADDRESS = environment.COMMON.MAIN_ADDRESS || '';
  TOKEN = 'WOL';
  PRICE = 'N/A';
  TOTAL_SUPPLY = '100,000,000';
  MARKET_CAP = 'N/A';
  BNB_PRICE = 'N/A';
  LP_HOLDINGS = '$500,000';

  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  herf() {
    location.href = this.MU_OF_HEROES;
  }

  copyAddress() {
    copy(this.MAIN_ADDRESS);
    this.toastrService.success(this.translateService.translate('messages.copied'));
  }

}
