<div class="area" [ngStyle]="{ 'min-height': isPopup && !isShow ? '26.625rem' : '31.625rem' }">
  <div class="header">
    <div class="close" (click)="close()">
      <i class="fa-solid fa-xmark"></i>
      <!-- <fa-icon [icon]="faTimes"></fa-icon> -->
    </div>
    <div class="h-text">{{ 'private-sale.title' | translate }}</div>
  </div>
  <div class="container body">
    <div class="countdown">
      <div class="title">{{ getTitle() }}</div>
      <div class="t-area">
        <div class="t-text">
          <span class="time pink">{{ DAYS }}</span>
          <span class="title left">{{ 'private-sale.days' | translate }}</span>
        </div>
        <span class="time">:</span>
        <div class="t-text">
          <span class="time pink">{{ HOURS }}</span>
          <span class="title left">{{ 'private-sale.hours' | translate }}</span>
        </div>
        <span class="time">:</span>
        <div class="t-text">
          <span class="time pink">{{ MINUTES }}</span>
          <span class="title left">{{ 'private-sale.minutes' | translate }}</span>
        </div>
        <span class="time">:</span>
        <div class="t-text">
          <span class="time pink">{{ SECONDS }}</span>
          <span class="title left">{{ 'private-sale.seconds' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="line-bot" *ngIf="!isPopup">
      <div class="line-gra"></div>
      <div class="line-block"></div>
    </div>
    <div class="row detail" *ngIf="isPopup">
      <div class="col-md-4 line">
        <div class="title">{{ 'private-sale.text1' | translate }}</div>
        <div class="text">{{ 'private-sale.detail1' | translate }}</div>
      </div>
      <div class="col-md-4 line">
        <div class="title">{{ 'private-sale.text2' | translate }}</div>
        <div class="text">{{ 'private-sale.detail2' | translate }}</div>
      </div>
      <div class="col-md-4">
        <div class="title">{{ 'private-sale.text3' | translate }}</div>
        <div class="text">{{ 'private-sale.detail3' | translate }}</div>
      </div>
    </div>

    <div class="detail-sm" *ngIf="isPopup">
      <div class="row custom">
        <div class="col-6 title">{{ 'private-sale.text1' | translate }}</div>
        <div class="col-6 text">{{ 'private-sale.detail1' | translate }}</div>
      </div>
      <div class="row custom">
        <div class="col-6 title">{{ 'private-sale.text2' | translate }}</div>
        <div class="col-6 text">{{ 'private-sale.detail2' | translate }}</div>
      </div>
      <div class="row custom">
        <div class="col-6 title">{{ 'private-sale.text3' | translate }}</div>
        <div class="col-6 text">{{ 'private-sale.detail3' | translate }}</div>
      </div>
    </div>

    <div class="submit" *ngIf="isPopup && isShow">
      <button class="btn button" (click)="register()">{{ 'private-sale.register-now' | translate }}</button>
    </div>
    <div class="wallet" *ngIf="!isPopup">
      <span class="title">{{ 'private-sale.wallet-text' | translate }}</span>
      <div class="t-address">
        <span class="address">0x5eeb28b5aef44b6664b342d23b1aadce84196386</span>
        <span class="copy" (click)="copy()">
          <!-- <fa-icon [icon]="faCopy" [styles]="{ color: '#7a6fff' }"></fa-icon> -->
          <i class="fa-solid fa-copy"></i>
          {{ 'common.copy' | translate }}
        </span>
      </div>
    </div>

    <div class="row form" *ngIf="!isPopup">
      <div class="col-md-5 info">
        <span class="t-info">{{ 'private-sale.info' | translate }}</span>
        <div class="r-custom">
          <span class="title left">{{ 'private-sale.text1' | translate }}</span>
          <span class="text left">{{ 'private-sale.detail1' | translate }}</span>
        </div>
        <div class="t-space"></div>
        <div class="r-custom">
          <span class="title left">{{ 'private-sale.text2' | translate }}</span>
          <span class="text left">{{ 'private-sale.detail2' | translate }}</span>
        </div>
        <div class="t-space"></div>
        <div class="r-custom">
          <span class="title left">{{ 'private-sale.text3' | translate }}</span>
          <span class="text left">{{ 'private-sale.detail3' | translate }}</span>
        </div>
        <div class="t-space"></div>
      </div>
      <!-- <div class="t-space"></div> -->

      <form class="col-md-7 info register" [formGroup]="registerForm">
        <span class="t-info">{{ 'private-sale.register' | translate }}</span>
        <span class="title left">{{ 'private-sale.mail' | translate }}</span>
        <input #mail type="text" id="mail" class="input" formControlName="mail" autocomplete="off"
          (input)="onInput('mail', mail.value)" [readonly]="isSubmit" [ngStyle]="{
            'margin-bottom': error.mail ? '0px' : '0.8125rem',
            outline: error.mail ? 'none' : '',
            border: error.mail ? '1px solid red' : '',
            'border-radius': error.mail ? '5px' : ''
          }" />
        <span class="title left error">{{ error.mail }}</span>
        <span class="title left">{{ 'private-sale.address' | translate }}</span>
        <input #address type="text" id="address" class="input" formControlName="address" autocomplete="off"
          (input)="onInput('address', address.value)" [readonly]="isSubmit" [ngStyle]="{
            'margin-bottom': error.address ? '0px' : '0.8125rem',
            outline: error.address ? 'none' : '',
            border: error.address ? '1px solid red' : '',
            'border-radius': error.address ? '5px' : ''
          }" />
        <span class="title left error">{{ error.address }}</span>
        <button type="button" class="submit cap" (click)="submit()" *ngIf="!isSubmit">
          {{ 'private-sale.submit' | translate }}
        </button>
        <button type="button" class="submit loading" *ngIf="isSubmit">
          <img class="icon" src="assets/images/loading.gif" />
        </button>
      </form>
    </div>
  </div>
  <div class="line-top" *ngIf="!isPopup"></div>
  <div class="footer" *ngIf="!isPopup">
    <div class="t-footer">
      {{ 'private-sale.note' | translate }}
    </div>
  </div>
</div>