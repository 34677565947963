import { Directive,ElementRef, HostBinding, Input } from '@angular/core';
@Directive({
  selector: '[medal]',
  host: {
    // '(error)': 'updateUrl()',
    // '[src]': 'src',
    // '[style]': 'style',
  },
})
export class WolMedalDirective {
  @Input()
  medal?: string;

  @HostBinding('medal')
  medalAtt?: string;

  constructor(private el: ElementRef) {
    // el.nativeElement.setAttribute('status','nft');
    this.medalAtt = this.medal;

  }

  load(v : any) {
      console.log(this.medal,v)
  }

}
