// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isDevelop : true,
  COMMON: {
    LANG_DEFAULT: 'en_US',
    MAIN_ADDRESS: '0x5eeb28b5aef44b6664b342d23b1aadce84196386',
    MARKETPLACE: 'https://marketplace.battlecity.io/',
    TRACKING_CODE: 'UA-212744394-1',
  },
  MAIL: {
    USERNAME: 'api',
    KEY: '4a2b59715090bd2e78851566cd73b7bc-adf6de59-e5635b59',
    PUBLIC_KEY: 'pubkey-2e8829836661d7ebe6834764b4b9e765',
    DOMAIN: 'marketing.battlecity.io',
    SUBCRIBE_DOMAIN: 'subscribe@marketing.battlecity.io',
    FROM: 'WOL <postmaster@marketing.battlecity.io>',
    DISCOVERY_DOCS: 'https://sheets.googleapis.com/$discovery/rest?version=v4',
    SCOPE: 'https://www.googleapis.com/auth/spreadsheets',
    SPREAD_SHEET_ID: '1FyQ1EgEcjCCh2V1b1t0I73ELLDugeEgCbIg1L9LlUeE',
    CREDENTIALS: {
      "type": "service_account",
      "project_id": "private-sale-1",
      "private_key_id": "b0ede2d0e779efebda60343f21a367ebfcd8eae1",
      "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCfYvCWH5OxtVg3\naBDU/dJPcDAisrDyM/GodqFFf8GSt9TKLKGWPF6woydU8z64FTXlbJ6G/M1kj4GY\n9+kPrJX6krP/cVhOfFtUyXL0kROHOlUevjBvSWjbIQRvxchHpxuhfbvKUGe5zyyY\njTePXzORuleTBOa4HT5mDHL6Jm5ez8qLZW79b8KLuosoYzhvxBhSDcooOB2Sw036\nqLQVhhzYeK12l0OTMUySkxjmkJwJ+RTRPA2wFEqSBnzRURWUW0P+/PGtN+xOdKKi\n1zJBbhCNqWXo3IWGcxqgLiozp/qOZcf9P20ez+xPmGigPcAoQJOJooYLZGpDGaL9\npmNx8FCdAgMBAAECggEAFBagRAuOzeMRT0pqdK3ZB/IX3weujZhGaPkqslu7xgYt\nZ53gb9MEDDdECczuprzlohjikhjiuj3sE5+LXFJgRMigrx1WB+5chH8vYeu3DmAn\nwkwuRph2AshSitPevkktjlkOhh1ENYMLF98UlZlmIoPd7cW8XpAxASOoiia12nUb\nIvnmnigEaDZVfNw/+lWg1IwNFvy/Ien3+HOQcFi89LBcEWEKx3USo/7tzaUMrG2F\nrrWeswZNTSzaQCinL+HVH02HMBSSJQKYQHqSXJVYlRnzX6+mq4F2SpJbbevtiuMK\nax96fNqugPmDJcAhs/CDj3OGJu26rXb2EiMPUhFfgQKBgQDKBUxLRptQpjJ2MYCs\n4NZpQALkb8wT2n3+ugDCE5sF/OWozjZtA4+lEgCabgVO4Ii2E9Lj/xp6xxST3tL2\n3RfXV6ImcLDe8DC/kzu0rj+AD8dxTG64Gic4+EByceEXKREJwsQPA4gARMggskl3\nO15STWI3ZYWS6MuIC0BigRcyxQKBgQDJ+VvpEvuKT9BN5FwCvP7xFF7mH0LQXN66\nKhuVJ/DgJG2t8rn35M3Yzz7CBGs+vEbGP5oWdLHl//ARdFL/lSPH3HTAJzZHNrIO\n2fbknvfbaxWi/MjZGjk66fwpDshkfH7aTYO/adefz44Tovlidg0KUTsM/cUBZ2Ut\noegBB6Mj+QKBgHnb8EWH9bAUUGQTQDcH+M3MGvKIrWVcapv4vvRStzRJdI76tMHV\nYBSGY27D5tfJgS2zj4aQHvzokXUQ99iJTRRMfDcLyuTvpbgpq941LYCmA/xHypGq\n1PX8aYOfRdOepfRpU7JqwuLGmqF+aepOGXszyob36jbPzdXmU5QXolKxAoGAKIEo\njIJxDZjaBnTweuAaJJDYQ0Arrwug4ybEp1H+PYUwtbzCzNMG8R8Gz/QA6fDqJKHm\nrfAzKRP9uhJg/r6TEWbFxy9V9Ewkw1O07r5uqIM+MJSPVvKqXYVFO5qqV3ScH/2B\nmWaJhpQiQw/hAXz5OoN12zIdSx3ILbGVjHdSoUkCgYEAlI8h+S4MxTMWunkhDunJ\nqNSBe5R1KHR5tncLARqIZ77qXIUUo0B/PQShOSJKu5dNpZToiAmyDeoelcIaTC0o\nwc9g05j2yyKLj7i1rikSxOrdly2ThijouyhmUXbFgBOZkOfZZ+FX4biHfC2Z8S0b\ngJnGzYBYRCyJysVMiQAp2wY=\n-----END PRIVATE KEY-----\n",
      "client_email": "private-sale-1@private-sale-1.iam.gserviceaccount.com",
      "client_id": "112866482611799949439",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/private-sale-1%40private-sale-1.iam.gserviceaccount.com"
    }
  },
  PRIVATE_SALE: {
    START: 1640080800000,
    END: 1641142800000
  },
  BASE_URL: 'https://dev.api.battlecity.io',
  SYSTEM_DEFAULT: 'battle_city',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
