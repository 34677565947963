import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WolTopButtonComponent } from './wol-top-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [
    WolTopButtonComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    BrowserModule
  ],
  exports: [
    WolTopButtonComponent
  ]
})
export class WolTopButtonModule { }
