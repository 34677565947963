import { Action } from '@ngrx/store';

// [AUTH] Auth module
export enum EvnActionTypes {
    GET_ENV = '[ENV] GET ENV',
    ADD_ENV = '[ENV] ADD ENV'
}


export class GET_ENV implements Action {
    readonly type = EvnActionTypes.GET_ENV;
}

export class ADD_ENV implements Action {
    readonly type = EvnActionTypes.ADD_ENV;
    public payload: any;
    constructor(_payload: any) {
        this.payload = _payload;
        if (this.payload['TARGET_AREA']) {
            sessionStorage.setItem('TARGET_AREA', this.payload['TARGET_AREA'])

        }
    }
}

export type EnvActionsUnion = GET_ENV | ADD_ENV;
