<ng-template #content let-c="close" let-d="dismiss">
    <div class="close_icon">
        ✖
    </div>
    <div class="modal_wrapper">
        <img src="assets/images/warning.svg" alt="" srcset="" style="margin-bottom: 49px;">
        <div class="message">
            {{getTranslate('common.confirm.message')}}
        </div>
        <div class="button_group">
            <div class="button" button_yes (click)="close('yes')">
                <div class="text">
                    {{getTranslate('common.confirm.yes')}}
                </div>
            </div>

            <div class="button" button_no (click)="close('no')">
                <div class="text">
                    {{getTranslate('common.confirm.no')}}
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template *ngIf="isShow then content">
</ng-template>