import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Moralis } from "moralis"
import { Cookie } from "ng2-cookies/ng2-cookies";
import { BaseService } from '../base/wol-base.service';
import { EnvironmentService } from '../env/wol-enviroment.service';
import { AppState } from '../../store/reducers';
import { Login, LoginSuccess, Logout } from '../../store/actions';

@Injectable({ providedIn: 'root' })
export class WolAuthService extends BaseService {
    private environment: any;
    private web3 = new Moralis.Web3((window as any).ethereum);

    constructor(
        http: HttpClient,
        env: EnvironmentService,
        private store: Store<AppState>,
    ) {
        super(http, env);
        this.environment = env.getEnv() || {};
    }

    async getCurrentAccount() {
        const currentUser = await this.post('/auth/account-info', {});
        if (currentUser?.status === 'OK' && currentUser?.result?.address) {
            let balances = {
                battle_city_mu_wol_balance: parseFloat(currentUser?.result?.balances?.find((item: any) => (item.game === 'battle_city_mu' && item.code === 'wol'))?.balance || "0.00").toFixed(2),
                battle_city_mu_ken_balance: parseFloat(currentUser?.result?.balances?.find((item: any) => (item.game === 'battle_city_mu' && item.code === 'ken'))?.balance || "0.00").toFixed(2),
                battle_city_tank_wol_balance: parseFloat(currentUser?.result?.balances.find((item: any) => (item.game === 'battle_city_tank' && item.code === 'wol'))?.balance || "0.00").toFixed(2),
                battle_city_tank_ken_balance: parseFloat(currentUser?.result?.balances.find((item: any) => (item.game === 'battle_city_tank' && item.code === 'ken'))?.balance || "0.00").toFixed(2)
            };
            this.store.dispatch(new Login({
                address: currentUser?.result.address,
                balances: balances
            }));
            this.store.dispatch(new LoginSuccess);
            return currentUser?.result;
        } else {
            return this.logout();
        }
    }

    async logout() {
        Cookie.delete('accessToken');
        // await Moralis.User.logOut();
        this.store.dispatch(new Logout);
        // location.reload();
    }

    async login() {
        // Check if MetaMask is installed
        if (!(window as any).ethereum) {
            window.alert('Please install MetaMask first.');
            return;
        }

        if (!this.web3) {
            try {
                // Request account access if needed
                await (window as any).ethereum.enable();

                // We don't know window.web3 version, so we use our own instance of Web3
                // with the injected provider given by MetaMask
                this.web3 = new Moralis.Web3((window as any).ethereum);
            } catch (error) {
                window.alert('You need to allow MetaMask.');
                return;
            }
        }

        let user: any = Moralis.User.current();
        if (!user) {
            user = await Moralis.Web3.authenticate()
                .then(function (user: any) {
                    console.log("logged in user:", user);
                    console.log(user.get("ethAddress"));
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }

        // @ts-ignore
        const coinbase = await this.web3.eth.getCoinbase();
        if (!coinbase) {
            window.alert('Please activate MetaMask first.');
            return;
        }

        const publicAddress = coinbase.toLowerCase();
        let checkArea = sessionStorage.getItem('TARGET_AREA');
        if (!checkArea) {
            sessionStorage.setItem('TARGET_AREA', 'MARKETPLACE');
        }
        const result = await this.post('/auth/nonce', {
            system: this.environment.SYSTEM_DEFAULT,
            game: this.environment['MARKETPLACE'].GAME,
            api_key: this.environment['MARKETPLACE'].API_KEY,
            address: publicAddress
        });
        if (result?.status === 'OK') {
            if (result?.result?.length !== 0) {
                const currentNonce = result?.result.nonce
                const address = result?.result.address

                //sigh with MetaMask
                const signature = await this.web3!.eth.personal.sign(
                    `You are signing your one-time nonce: ${currentNonce}`,
                    address,
                    '' // MetaMask will ignore the password argument here
                );
                const getAccessTokenResult = await this.post('/auth/token', {
                    address: address,
                    signature: signature
                });
                if (getAccessTokenResult?.status === 'OK') {
                    if (getAccessTokenResult?.result?.length !== 0) {
                        const accessToken = getAccessTokenResult?.result?.accessToken;
                        // Cookie.set('accessToken', accessToken); Uncomment to run local
                        Cookie.set('accessToken', accessToken, undefined, '/', this.getEnvironmentName() + '.battlecity.io');
                    }
                }
            }
        }
        await this.getCurrentAccount();
        return result;
    }

    isLogined() {
        return !!Cookie.get('accessToken');
    }

    getEnvironmentName() {
        if (this.environment[`isDevelop`]) {
            return '.dev';
        }
        else if (this.environment[`isStaging`]) {
            return '.stg';
        }
        return ''
    }


    isValidAddress(rawInput: string) {
        try {
            const address = this.web3.utils.toChecksumAddress(rawInput);
            return true;
        } catch (e) {
            return false;
        }
    }

}
