<div class="detail">
  <div class="heros">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 group">
          <div class="arrow_left">
            <wol-arrow></wol-arrow>
          </div>
          <div class="slide">
            <div class="banner"></div>
            <div class="image">
              <img
                src="assets/images/hero-2.png"
                style="width: 26rem; height: 22rem"
              />
            </div>
            <!-- <div class="btn" (click)="herf()"> -->
              <div class="btn">
              <!-- <a href="{{ MU_OF_HEROES }}" class="btn-text" target="_blank">{{ 'common.play-now' | translate }}</a> -->
              <a class="btn-text">{{ 'common.coming-soon' | translate }}</a>
            </div>
          </div>
          <div class="arrow_right">
            <wol-arrow [arrow]="'right'"></wol-arrow>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="text_d">{{ 'common.mu-of-heroes' | translate }}</div>
          <wol-line [center]="false"></wol-line>
          <div class="content">
            <div class="text_h">{{ 'homepage.earning-arena' | translate }}</div>
            <div class="text_s">
              {{ 'homepage.detail-earning-arena' | translate }}
            </div>
          </div>

          <div class="content">
            <div class="text_h">{{ 'homepage.battle-arena' | translate }}</div>
            <div class="text_s">
              {{ 'homepage.detail-battle-arena' | translate }}
            </div>
          </div>

          <div class="content">
            <div class="text_h">{{ 'homepage.events' | translate }}</div>
            <div class="text_s">{{ 'homepage.detail-events' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info">
    <div class="group">
      <div class="sub">
        <div class="icon">
          <img
            class="icon_image"
            src="assets/images/wol.png"
          />
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 wol_coin">
              <div class="text1">{{ 'common.wol-coin' | translate }}</div>
              <div class="text2">
                {{ 'common.world-of-legends' | translate }}
              </div>
            </div>
            <div class="col col-xl-9 wol_address">
              <div class="contract">{{ 'common.contract' | translate }}</div>
              <div style="display: flex">
                <div class="address">
                  {{ MAIN_ADDRESS }}
                </div>
                <div class="copy" (click)="copyAddress()">{{ 'common.copy' | translate }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="new_line"></div>

        <div class="container-fluid">
          <div class="row fixed_area">
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3">{{ 'common.token' | translate }}</div>
              <div class="text4">{{ 'common.wol' | translate }}</div>
            </div>
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3">{{ 'common.price' | translate }}</div>
              <div class="text4">{{ PRICE }}</div>
            </div>
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3">{{ 'homepage.total-supply' | translate }}</div>
              <div class="text4">{{ TOTAL_SUPPLY }}</div>
            </div>
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3">{{ 'homepage.market-cap' | translate }}</div>
              <div class="text4">{{ MARKET_CAP }}</div>
            </div>
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3" style="text-transform: none;">{{ 'homepage.bnb-price' | translate }}</div>
              <div class="text4">{{ BNB_PRICE }}</div>
            </div>
            <div class="col-4 col-lg-4 col-xl-2 fixed_item">
              <div class="text3" style="text-transform: none;">{{ 'homepage.lp-holdings' | translate }}</div>
              <div class="text4">{{ LP_HOLDINGS }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="roadmap">
    <div class="space"></div>
    <div class="text">{{ 'homepage.roadmap' | translate }}</div>
    <div class="container content">
      <div class="row space_left">
        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q1' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q1-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q1-text2' | translate
            }}</span>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q2' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q2-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q2-text2' | translate
            }}</span>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q3' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q3-text1' | translate
            }}</span>
            <span class="text2" style="min-height: 2.3125rem;">{{
              'homepage.roadmap-q3-text2' | translate
            }}</span>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q4' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q4-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q4-text2' | translate
            }}</span>
          </div>
        </div>



        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q5' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q5-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q5-text2' | translate
            }}</span>
            <span class="text2" style="min-height: 2.3125rem;">{{
              'homepage.roadmap-q5-text3' | translate
            }}</span>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q6' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q6-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q6-text2' | translate
            }}</span>
            <span class="text3">{{
              'homepage.roadmap-q6-text3' | translate
            }}</span>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q7' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q7-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q7-text2' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q7-text3' | translate
            }}</span>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-sm-6 grid_start">
          <div class="text1">{{ 'homepage.roadmap-q8' | translate }}</div>
          <div class="line_text">
            <wol-line [style]="2" [center]="false"></wol-line>
          </div>
          <div class="sub_text">
            <span class="text2">{{
              'homepage.roadmap-q8-text1' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q8-text2' | translate
            }}</span>
            <span class="text2">{{
              'homepage.roadmap-q8-text3' | translate
            }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
