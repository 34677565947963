import { Action } from '@ngrx/store';

// [Filter Feature]
// - navbar-filter-items-game
export enum FilterActionTypes {
  FILTER_GAME = '[FILTER GAME] FILTER GAME',
  FILTER_SORTBY = `[FILTER SORTBY] FILTER SORTBY`,
  FILTER_CATEGORY = `[FILTER CATEGORY] FILTER CATEGORY`,
  FILTER_STATUS = `[FILTER STATUS] FILTER STATUS`,
  FILTER_PRICE = `[FILTER PRICE] FILTER PRICE`,
  FILTER_RESET = `[FILTER RESET] FILTER RESET`,
  FILTER_CARD_STATUS = `[FILTER CARD STATUS] FILTER CARD STATUS`,
  FILTER_CARD_GAME = `[FILTER CARD GAME] FILTER CARD GAME`,
  FILTER_CARD_CATEGORIES = `[FILTER CARD CATEGORIES] FILTER CARD CATEGORIES`
}



export class FILTER_GAME implements Action {
  readonly type = FilterActionTypes.FILTER_GAME;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_SORTBY implements Action {
  readonly type = FilterActionTypes.FILTER_SORTBY;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_CATEGORY implements Action {
  readonly type = FilterActionTypes.FILTER_CATEGORY;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_STATUS implements Action {
  readonly type = FilterActionTypes.FILTER_STATUS;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_CARD_STATUS implements Action {
  readonly type = FilterActionTypes.FILTER_CARD_STATUS;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_CARD_CATEGORIES implements Action {
  readonly type = FilterActionTypes.FILTER_CARD_CATEGORIES;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_CARD_GAME implements Action {
  readonly type = FilterActionTypes.FILTER_CARD_GAME;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_PRICE implements Action {
  readonly type = FilterActionTypes.FILTER_PRICE;
  public payload: any;
  constructor(public _payload: object) {
    this.payload = _payload;
  }
}

export class FILTER_RESET implements Action {
  readonly type = FilterActionTypes.FILTER_RESET;
  public payload: any;
  constructor(public _payload?: object) {
    this.payload = _payload;
  }
}

export enum FILTER_RESET_TYPE {
  GAME = 'GAME',
  STATUS = 'STATUS',
  PRICE = 'PRICE',
  CATEGORY = 'CATEGORY'
}

export type FilterActionsUnion =
  | FILTER_GAME
  | FILTER_SORTBY
  | FILTER_CATEGORY
  | FILTER_RESET
  | FILTER_STATUS
  | FILTER_CARD_STATUS;
