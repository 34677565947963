/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as en from '../../../assets/i18n/en-US.json';
import * as zh from '../../../assets/i18n/zh-CN.json';
import * as ja from '../../../assets/i18n/ja-JP.json';
import * as ko from '../../../assets/i18n/ko-KP.json';
import * as ru from '../../../assets/i18n/ru-RU.json';
import * as th from '../../../assets/i18n/th-TH.json';
import * as tl from '../../../assets/i18n/tl-PH.json';
import * as vi from '../../../assets/i18n/vi-VN.json';
import { EnvironmentService } from '../env/wol-enviroment.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslateService {
  language = new BehaviorSubject<any>(null);
  environment: any;
  currentLang = '';
  constructor(envService: EnvironmentService) {
    this.environment = envService;
    const defaultLang = this.environment.COMMON?.LANG_DEFAULT;
    const currentLang = localStorage.getItem('language');
    this.currentLang = currentLang || defaultLang;

    this.language.subscribe((lang) => {
      if (lang) {
        this.currentLang = lang.id || defaultLang;
      }
    });
  }
  captions: Record<string, any> = {
    en_US: en,
    zh_CN: zh,
    ja_JP: ja,
    ko_KP: ko,
    ru_RU: ru,
    th_TH: th,
    tl_PH: tl,
    vi_VN: vi
  };

  /**
   * Translate web by lang
   *
   * @param {string} value
   * @returns {string} translated value
   * @memberof TranslationService
   */
  translate(value: string): string {
    const code = (value || '').toLowerCase();
    const captionI18n = this.captions[this.currentLang];
    if (captionI18n) {
      const result = this.deepFind(captionI18n, code);
      return result || code;
    } else {
      return code;
    }
  }

  private deepFind(data: any, code: string) {
    const paths = code.split('.');
    let current = data;

    for (let i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }
}
