import { Injectable } from '@angular/core';
import { CardItem } from '../../components/wol-card-asset/wol-card-asset.component';
import { BaseService } from '../base/wol-base.service';

@Injectable({providedIn: 'root'})
export class WolComponentService extends BaseService {
    async getView(game?: string, type?: any, target_id?: any) {
        return await this.post('/common/view', {
            game,
            type,
            target_id
        })
    }


    async createView(game: string, type: string, address: string, target_id?: string) {
        return await this.post('/common/view/create', {
            game,
            type,
            target_id,
            create_by: address,
            change_by: address
        })
    }

    async getAssetDetailById(condition: any) {

        const result = await this.post('/market/asset', {
            ...condition,
        });
        if (result?.status === 'OK') {
            return this.tranformAssetDetailData(result?.result);
        }
        return;
    }

    tranformAssetDetailData(assetData: any) {
        const dto: CardItem = {
            name: assetData?.name,
            game: assetData?.game,
            token_id: assetData?.token_id, //token id (status nft)
            price: assetData?.price,
            image: 'assets/images/' + assetData?.image_url || '',
            description: assetData?.description || '',
            status: assetData?.status,
            type: assetData?.type,
            category: assetData?.category,
            class: assetData?.class,
            asset_id: assetData?.assetId,
            attribute: assetData?.attribute,
            change_at: assetData?.change_at,
            create_at: assetData?.create_at,
            id: assetData?._id,
            view: assetData?.view,
            owner: assetData?.owner
        };
        return dto;
    }
}