/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  products = [
    {
      image: 'assets/images/mrnambui.png',
      name: 'NAM BUI',
      job: 'CEO'
    },
    {
      image: 'assets/images/mrkyanh.png',
      name: 'KIAN BUI',
      job: 'COO'
    },
    {
      image: 'assets/images/mrphuclam.png',
      name: 'PHUC LAM',
      job: 'CTO'
    },
    {
      image: 'assets/images/mranhtran.png',
      name: 'ANH TRAN',
      job: 'Blockchain Expert'
    },
    {
      image: 'assets/images/mrsycuong.png',
      name: 'CUONG LE',
      job: 'Business Development & Community Manager'
    },
    {
      image: 'assets/images/mrsdunguyen.png',
      name: 'DU NGUYEN',
      job: 'Content Creator'
    },
    {
      image: 'assets/images/mrnghiale.png',
      name: 'NGHIA LE',
      job: 'Legal Counsel'
    },
    {
      image: 'assets/images/mrtanduong.png',
      name: 'TAN DUONG',
      job: 'Art Director'
    },
    {
      image: 'assets/images/mrsonyhuynh.png',
      name: 'SONY HUYNH',
      job: 'DevSecOps Engineer'
    }
  ];
  responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '1100px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '576px',
      numVisible: 2,
      numScroll: 2,
    },
  ];

  ngOnInit(): void {
    const items = document.querySelectorAll('.carousel .carousel-item');
    items.forEach((element) => {
      const minPerSlide = 4;
      let next = element.nextElementSibling;
      for (let i = 1; i < minPerSlide; i++) {
        if (!next) {
          next = items[0];
        }
        const cloneChild = next.cloneNode(true);
        element.appendChild((cloneChild as any).children[0]);
        next = next.nextElementSibling;
      }
    });
  }
}
