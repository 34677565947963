<div class="filter_wrapper" [style.width]="width">
    <div class="filter">
        <div class="all_items">
            <div class="line">
            </div>
            <div class="all_text">All Items</div>
            <div class="result_text">{{getTotalItems() | json}} Results</div>
        </div>
        <div class="sort" *ngIf="!hiddenAttributes.includes('filter')">
            <select class="form-select" aria-label="All games" [formControl]="select_games"
                *ngIf="showFilters('all_games')">
                <option selected value="">{{ 'my-items.all-games' | translate }}</option>
                <option *ngFor="let o of all_games" [value]="stringgifyData(o)">{{o.value}}</option>

            </select>
            <select class="form-select" aria-label="All games" *ngIf="showFilters('all_status')"
                [formControl]="all_items">
                <option selected value="">{{ 'my-items.all-items' | translate }}</option>
                <option *ngFor="let o of filterByStatuses" [value]="stringgifyData(o)">{{ o.value}}</option>

            </select>
            <select class="form-select" aria-label="Categories" *ngIf="showFilters('categories')"
                [formControl]="select_categories">
                <option selected value="">{{ 'my-items.categories' | translate }}</option>
                <option *ngFor="let o of all_categories" [value]="stringgifyData(o)">{{o.value}}</option>

            </select>

            <select class="form-select" aria-label="Sort By" [formControl]="sortby" style="min-width: 200px;"
                *ngIf="showFilters('sort_by')">
                <option selected value="">Sort By</option>
                <option *ngFor="let o of sortbyCreated" [value]="stringgifyData(o)">{{o.value | translate}}</option>
            </select>
        </div>
    </div>
    <div class="tag_area" *ngIf="filterTags.length !== 0">
        <wol-chip [text]="f?.value" *ngFor="let f of filterTags" [item]="f" [items]="filterTags"></wol-chip>
        <div class="clear_text" *ngIf="filterTags.length!==0" (click)="resetFilter()">Clear All</div>
    </div>

    <i *ngIf="this.listItems.length === 0 && itemLoading.length ===0 && isShowResultNum" style="color: gray;">{{
        'There is no result !' }}</i>

    <div class="container__" [scrollWindow]="isScroll" [alwaysCallback]="false" (scrolled)="onScroll()" infiniteScroll
        [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="10">
        <div class="assets">
            <wol-card-asset *ngFor="let c of listItems" [item]="c" [hiddenAttributes]="hiddenAttributes"
                (click)="handleClickCard(c)">
            </wol-card-asset>
            <wol-card-asset *ngFor="let c of itemLoading" [item]="c" [isLoading]="true"
                [hiddenAttributes]="hiddenAttributes"></wol-card-asset>
        </div>
    </div>
    <div style="margin-top: 50px; display: flex; justify-content: center;">
        <p *ngIf="isEndData" style="color: #d8e2ea;">{{ 'common.data.data-out-of' | translate }}</p>
    </div>
</div>