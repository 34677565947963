/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArrayFull, isObject } from './checks.util';

export class Utils {
  static clone(obj: any): any {
    if (isArrayFull(obj)) {
      return Object.assign([], obj);
    } else if (isObject(obj)) {
      return Object.assign({}, obj);
    }
  }

  static randomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * max) + min;
  }
}
