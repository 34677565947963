import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { WolChipComponent } from './wol-chip.component';



@NgModule({
    declarations: [
        WolChipComponent
    ],
    imports: [
        FontAwesomeModule,
        BrowserModule,
        CommonModule,
    ],
    exports: [
        WolChipComponent
    ]
})
export class WolChipModule { }
