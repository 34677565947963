import {Injectable,} from '@angular/core';
import {Moralis} from "moralis";
import Web3 = Moralis.Web3;
import {MetaMaskInpageProvider} from '@metamask/providers';
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../env/wol-enviroment.service";
import {BaseService} from "../base/wol-base.service";
// @ts-ignore
const ethereum = window.ethereum as MetaMaskInpageProvider;


const nft_contract_address = "0x77664Bc61BE8077fA09F60673eD145E2d3421dbe"
// @ts-ignore
const web3 = new Web3(window.ethereum);
export type User = Moralis.User<Moralis.Attributes>;

@Injectable({
  providedIn: 'root'
})
export class MoralisService extends BaseService{
  currentUser?: User;
  environment: any;
  private web3;
  private ethereum;

  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env);
    this.environment = env.getEnv() || {};
    // @ts-ignore

    // this.moralis = Moralis;
    this.web3 = new Moralis.Web3;
    this.ethereum = (window as any).ethereum as MetaMaskInpageProvider;
  }

  init(): void {
      Moralis.start({
        appId: this.environment.moralis.appId,
        serverUrl: this.environment.moralis.serverUrl
      })
        .then(() => {
          console.info('Moralis has been initialised.');
        })
        .finally(() => this.setLoggedInUser(Moralis.User.current()));
  }

  login(provider: 'metamask' | 'walletconnect' = 'metamask') {
    (provider === 'metamask'
      ? Moralis.Web3.authenticate()
      : Moralis.Web3.authenticate({provider}))
      .then((loggedInUser) => this.setLoggedInUser(loggedInUser))
      .catch((e) => console.error(`Moralis '${provider}' login error:`, e));
  }

  logout() {
    Moralis.User.logOut()
      .then((loggedOutUser) => console.info('logout', loggedOutUser))
      // Set user to undefined
      .then(() => this.setLoggedInUser(undefined))
      // Disconnect Web3 wallet
      .then(() => Moralis.Web3.cleanup())
      .catch((e) => console.error('Moralis logout error:', e));
  }

  async mintNft( data:any) {
    // @ts-ignore
    const isWeb3Active = Moralis.ensureWeb3IsInstalled();
    if (isWeb3Active) {
      console.log("Activated");
    } else {
      console.log("not Activated");
      // @ts-ignore
      Moralis.enableWeb3();
    }
    // @ts-ignore


    const imageFile = new Moralis.File("dasldjlks.png", data);
    let imagetest = await imageFile.saveIPFS();
    // @ts-ignore
    const imageURI = await imagetest.ipfs();
    const metadata = {
      "name": "test",
      "description": "Daniel Test",
      "image": imageURI,
      "metadata": "https://api.battlecity.io/#/metadata/123456"
    }
    const metadataFile = new Moralis.File("metadata.json", {base64: btoa(JSON.stringify(metadata))});
    let test = await metadataFile.saveIPFS();
    // @ts-ignore
    const metadataURI = test.ipfs();
    // console.log(imageURI);
    const txt = await this.mintToken(metadataURI);
    console.log(txt);
  }

  async mintToken(_uri: any) {
    // @ts-ignore
    const encodedFunction =  web3.eth.abi.encodeFunctionCall({
      name: "mintToken",
      type: "function",
      inputs: [{
        type: 'string',
        name: 'tokenURI'
      }]
    }, [_uri]);

    const transactionParameters = {
      to: nft_contract_address,
      from: ethereum.selectedAddress,
      data: encodedFunction
    };
    return await ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
  }

  private setLoggedInUser(loggedInUser?: User) {
    this.currentUser = loggedInUser;
    console.info('Loggedin user:', loggedInUser);
  }
}
