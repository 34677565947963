<div class="coin">
  <div class="container">
    <div class="row">
      <div class="col-xl-6">
        <div class="area">
          <div class="ken">
            <img class="image" src="assets/images/ken.png" />
          </div>
          <div class="block-right">
            <img class="ar-right" src="assets/images/ar-right.png" />
          </div>
          <div class="wol">
            <img class="image" src="assets/images/wol.png" />
          </div>
          <div class="block-left">
            <img class="ar-left" src="assets/images/ar-left.png" />
          </div>
        </div>
      </div>
      <div class="col-xl-6 detail">
        <div class="title">{{ 'tokenomic.wol-ken' | translate }}</div>
        <wol-line [center]="false"></wol-line>
        <div class="info space">
          <img class="icon" src="assets/images/wol.png" />
          <div class="text">
            <span class="special">{{ 'tokenomic.wol-text-1' | translate }}</span
            >{{ 'tokenomic.wol-text-2' | translate }}
          </div>
        </div>
        <div class="info">
          <img class="icon" src="assets/images/ken.png" />
          <div class="text">
            <span class="special">{{ 'tokenomic.ken-text-1' | translate }}</span
            >{{ 'tokenomic.ken-text-2' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
