import { LoginActionsUnion, LoginActionTypes } from '../actions/wol-auth.actions';
import { AppState } from '.';

export interface AuthState {
    loggedIn: boolean;
    errorMsg: string;
    user: object;
    isLogging: boolean;
}

const initialState: AuthState = {
    loggedIn: false,
    errorMsg: '',
    user: {},
    isLogging: false
};

export const AuthReducer = (state = initialState, action: { type: string; payload?: any }) => {
    switch (action.type) {
        case LoginActionTypes.LOGIN:
            return {
                ...state,
                user: action.payload
            };
        case LoginActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true
            };
        case LoginActionTypes.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: null
            };
        case LoginActionTypes.LOGIN_FAIL:
            return {
                ...state,
                errorMsg: 'Invalid user credential'
            };
        case LoginActionTypes.IS_LOGIN:
            return {
                ...state,
                isLogging: true
            };
        case LoginActionTypes.LOGIN_DONE:
            return {
                ...state,
                isLogging: false
            };
        default:
            return state;
    }
}

export const getLoginState = (state: AppState) => state.authReducer.loggedIn;
export const getCurrentUser = (state: AppState) => state.authReducer.user;
export const getLoginStatus = (state: AppState) => state.authReducer.errorMsg;
export const getIsLoginState = (state: AppState) => state.authReducer.isLogging;
