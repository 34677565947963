import { Inject, Injectable, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ADD_ENV } from '../../store/actions/wol-env.action';
import { AppState } from '../../store/reducers';
import { getEnv } from '../../store/reducers/wol-env.reducer';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  _env: any;
  constructor(
    public store: Store<AppState>,
    @Inject('ENVIRONMENT') private env?: any
  ) {
    this._env = env;
    if (store) {
      setTimeout(() => {
        const config = sessionStorage.getItem('TARGET_AREA') ? { TARGET_AREA: sessionStorage.getItem('TARGET_AREA') } : {};
        store.dispatch(new ADD_ENV({ ...env, ...config }));
        store.pipe(select(getEnv)).subscribe(env => {
          this._env = env;
        })
      }, 50)
    }
  }
  getEnv = () => {
    return this._env;
  };
}
