import {Directive, ElementRef, Input} from '@angular/core';
import {EnvironmentService} from '@wol/ui';
import {
    AbstractControl,
    AsyncValidator,
    NG_ASYNC_VALIDATORS,
    ValidationErrors,
} from "@angular/forms";
import {Observable} from "rxjs";
import { map} from "rxjs/operators";
import {Cookie} from "ng2-cookies/ng2-cookies";
import {HttpClient} from "@angular/common/http";

@Directive({
    selector: '[withdrawBalanceExceed]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: WolCheckBalanceAddressDirective, multi: true}]
})
export class WolCheckBalanceAddressDirective implements AsyncValidator {
    @Input('withdrawBalanceExceed') amount = 0;
    @Input('withdrawBalanceExceedGame') game: any = '';
    @Input('withdrawBalanceExceedCoin') coin: any = '';
    private environment: any;

    constructor(private el: ElementRef,
                private env: EnvironmentService,
                private http: HttpClient
    ) {
        this.environment = env.getEnv() || {};
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        const headersRequest = {
            'Content-Type': 'application/json', // afaik this one is not needed
            Authorization: `Bearer ${Cookie.get('accessToken')}`, // token add if you have
        };
        let request: any = {
            code: [this.coin],
            game_ids: [this.environment[this.game.toUpperCase()]?.GAME]
        }
        const code: any = sessionStorage.getItem('TARGET_AREA');
        const source: any = sessionStorage.getItem('SOURCE');

        request['system'] = request?.system ?? this.environment?.SYSTEM_DEFAULT;

        request[`game`] = this.environment[code]?.GAME ?? this.environment[source]?.GAME ?? "NO_CODE";

        request['api_key'] = this.environment[code]?.API_KEY ?? this.environment[source]?.API_KEY ?? "NO_API_KEY";

        return this.http
            .post(
                this.environment?.BASE_URL + '/portal/get-balance-by-condition',
                request,
                {headers: headersRequest}
            )
            .pipe(
                map((data: any) => {
                    let balance = data?.result?.[0].balance;
                    if (parseFloat(this.el.nativeElement.value) > balance) {
                        return {withdrawBalanceExceed: {value: control.value}};
                    }
                    return null;
                })
            );

    }

}