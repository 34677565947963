import { AppState } from ".";
import { PageActionTypes } from "../actions/wol-page.action";

export interface Page {
    path: string;
    round: number

}

export interface PageState {
    page_data: Page[],
    isLoading: boolean;
}


const initialState: PageState = {
    page_data: [],
    isLoading: false
}


export const PageReducer = (state = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case PageActionTypes.ADD_ROUND_DATA:
            const find = state.page_data.filter(f => f?.path !== action.payload?.path);

            return {
                ...state,
                page_data: [...find, action.payload]
            }
        case PageActionTypes.RESET_ROUND_DATA:
            return {
                ...state,
                page_data: []
            }
        case PageActionTypes.PAGE_LOADING:
            return {
                ...state,
                isLoading: !state?.isLoading
            }
        case PageActionTypes.PAGE_UN_LOADING:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}

export const getPageData = (state: AppState) => state.pageRedcucer;
export const getPageLoading = (state: AppState) => state.pageRedcucer.isLoading;