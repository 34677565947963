export interface LangDto {
  id: string;
  name: string;
}

export interface KeyValueDto {
  key: string;
  value: string;
}

export interface MailAddressDto {
  mail: string;
  address: string;
  time: string;
}

export interface MasterDataDto {
  _id: string;
  class: string;
  code: string;
  value: string;
  parent_code: string;
  system: string;
}

export interface NFTItem {
  id?: string;
  name?: string;
  gameName?: KeyValueDto;
  code?: string;
  priceNFT?: number;
  symbol?: string;
  imageUrl?: string;
  typeUrl?: string;
  description?: string;
  status?: string;
  ownerOf?: string;
  type? :KeyValueDto;
  category? :KeyValueDto;
  class? : KeyValueDto;
  assetId? : string;
  attribute? : string;
  change_at? :number;
  create_at? :number;
  asset_detail_id? :string;
  view? :number;
  countData? :number | any
}


export interface TransactionCondition {
  tx_hash?: string;
  type?: string;
  coin?: string;
  date_from?: number;
  date_to?: number;
}


export interface Transaction extends TableExtension {
  hash?: string;
  type?: string;
  from?: string;
  ''?: string;
  to?: string;
  value?: string;
  date?: number;
  status?:string;
}


export interface TableExtension {
  highlight?: string[];
  capitalize?: string[];
  links?: { key: string; link: string }[]
}
