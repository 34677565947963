import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'wol-comming-soon',
    templateUrl: `./wol-comming-soon.component.html`,
    styleUrls: [`./wol-comming-soon.component.scss`]
})
export class WolCommingSoonComponent implements OnInit {
    constructor(activeRoute : ActivatedRoute) {
            activeRoute.data.subscribe(x => {
                      localStorage.page = x?.breadcrumb;
            })
     }

    ngOnInit(): void { }

    gotoHome() {
        location.href = "https://battlecity.io/";
    }
}
