<div class="core_team">
  <div class="text">{{ 'homepage.our-core-team' | translate }}</div>
  <wol-line></wol-line>
  <div class="space"></div>
  <p-carousel
    [value]="products"
    [numVisible]="4"
    [numScroll]="2"
    [circular]="false"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-product pTemplate="item">
      <div class="card person">
        <div class="card-img image_space" [ngStyle]="{ 'padding-bottom': product.name === 'CUONG LE' ? '0' : '1rem'}">
          <img src="{{ product.image }}" class="img-fluid" />
          <div class="name" [ngStyle]="{ 'margin-top': product.name === 'CUONG LE' ? '0.3125rem' : '1rem'}">{{ product.name }}</div>
          <div class="job">{{ product.job }}</div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
